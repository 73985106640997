import { IconComponent } from '@/lib/types'

export const CircleIcon: IconComponent = ({ ...properties }) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...properties}
    >
      <path
        d="M12 2C6.48698 2 2 6.48698 2 12C2 17.513 6.48698 22 12 22C17.513 22 22 17.513 22 12C22 6.48698 17.513 2 12 2ZM12 3.5C16.7031 3.5 20.5 7.29688 20.5 12C20.5 16.7031 16.7031 20.5 12 20.5C7.29688 20.5 3.5 16.7031 3.5 12C3.5 7.29688 7.29688 3.5 12 3.5Z"
        fill="#FAFAF9"
      />
    </svg>
  )
}

CircleIcon.displayName = 'CircleIcon'
