import { IconComponent } from '@/lib/types'

export const RenameIcon: IconComponent = ({ ...properties }) => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...properties}
    >
      <path
        d="M9.79175 1.66678C9.56605 1.66461 9.35772 1.78179 9.2427 1.97711C9.12986 2.17242 9.12986 2.41114 9.2427 2.60645C9.35772 2.80176 9.56605 2.91895 9.79175 2.91678H10.8334V17.0834H9.79175C9.56605 17.0813 9.35772 17.1985 9.2427 17.3938C9.12986 17.5891 9.12986 17.8278 9.2427 18.0231C9.35772 18.2184 9.56605 18.3356 9.79175 18.3334H11.3564C11.4237 18.3443 11.491 18.3443 11.5582 18.3334H13.1251C13.3508 18.3356 13.5591 18.2184 13.6741 18.0231C13.787 17.8278 13.787 17.5891 13.6741 17.3938C13.5591 17.1985 13.3508 17.0813 13.1251 17.0834H12.0834V2.91678H13.1251C13.3508 2.91895 13.5591 2.80176 13.6741 2.60645C13.787 2.41114 13.787 2.17242 13.6741 1.97711C13.5591 1.78179 13.3508 1.66461 13.1251 1.66678H9.79175ZM4.37508 5.00011C2.88637 5.00011 1.66675 6.21973 1.66675 7.70844V12.2918C1.66675 13.7805 2.88637 15.0001 4.37508 15.0001H10.0001V13.7501H4.37508C3.56345 13.7501 2.91675 13.1034 2.91675 12.2918V7.70844C2.91675 6.89681 3.56345 6.25011 4.37508 6.25011H10.0001V5.00011H4.37508ZM12.9167 5.00011V6.25011H15.2084C16.02 6.25011 16.6667 6.89681 16.6667 7.70844V12.2918C16.6667 13.1034 16.02 13.7501 15.2084 13.7501H12.9167V15.0001H15.2084C16.6971 15.0001 17.9167 13.7805 17.9167 12.2918V7.70844C17.9167 6.21973 16.6971 5.00011 15.2084 5.00011H12.9167Z"
        fill="currentColor"
      />
    </svg>
  )
}

RenameIcon.displayName = 'RenameIcon'
