import { IconComponent } from '@/lib/types'

export const CheckmarkDoneIcon: IconComponent = ({ ...properties }) => {
  return (
    <svg
      fill="none"
      height="24"
      viewBox="0 0 24 24"
      width="24"
      xmlns="http://www.w3.org/2000/svg"
      {...properties}
    >
      <path
        d="M20.2917 5.29163L9.00008 16.5885L4.70841 12.2916L3.29175 13.7083L9.00008 19.4114L21.7084 6.70829L20.2917 5.29163Z"
        fill="currentColor"
      />
    </svg>
  )
}

CheckmarkDoneIcon.displayName = 'CheckmarkDoneIcon'
