import { IconComponent } from '@/lib/types'

export const SendIcon: IconComponent = ({ ...properties }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      {...properties}
    >
      <path
        d="M12 2.92725L4.92706 10.0002L6.42706 11.5002L11 6.92725V21.0002H13V6.92725L17.5677 11.5002L19.0677 10.0002L12 2.92725Z"
        fill="currentColor"
      />
    </svg>
  )
}

SendIcon.displayName = 'SendIcon'
