import Fuse from 'fuse.js'
import {
  KeyboardEventHandler,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react'
import { createEditor, Descendant, Editor, Range } from 'slate'
import {
  Editable,
  ReactEditor,
  RenderElementProps,
  RenderLeafProps,
  Slate,
  withReact,
} from 'slate-react'

import { Element } from '@/features/task/components/chat/input/Element'
import { Leaf } from '@/features/task/components/chat/input/Leaf'
import { MentionMenu } from '@/features/task/components/chat/input/MentionMenu'
import { Portal } from '@/features/task/components/chat/input/Portal'
import {
  detectMentionStart,
  insertMention,
  parseMention,
} from '@/features/task/components/chat/input/utils'
import { withMentions } from '@/features/task/components/chat/input/withMentions'
import { useUsers } from '@/features/task/hooks/useUsers'
import { useClickOutside } from '@/lib/hooks/useClickOutside'

const editorStyle = {
  minHeight: 29,
}

const initialValue: Descendant[] = [
  {
    children: [
      {
        text: '',
      },
    ],
  },
]

export const ChatMessageInputWithMentions = ({
  onChange,
  onKeyDown,
}: {
  onChange: (value: string) => void
  onKeyDown: (event: React.KeyboardEvent<HTMLTextAreaElement>) => void
}) => {
  const [mentionStartRange, setMentionStartRange] = useState<
    Range | undefined
  >()
  const [index, setIndex] = useState(0)
  const [search, setSearch] = useState('')
  const mentionMenuReference = useRef<HTMLDivElement>(null)
  const renderElement = useCallback(
    (properties: RenderElementProps) => <Element {...properties} />,
    []
  )

  useEffect(() => {
    setIndex(0)
  }, [search])

  const renderLeaf = useCallback(
    (properties: RenderLeafProps) => <Leaf {...properties} />,
    []
  )
  const editor = useMemo(() => withMentions(withReact(createEditor())), [])

  const { users } = useUsers()
  const fuse = useMemo(
    () => new Fuse(users ?? [], { keys: ['name'], threshold: 0.5 }),
    [users]
  )

  const people = useMemo(
    () => [
      ...(search.length > 0
        ? fuse
            .search(search)
            .slice(0, 10)
            .map((result) => result.item)
        : (users ?? [])),
    ],
    [fuse, search, users]
  )

  const handleKeyDown = useCallback(
    (event: React.KeyboardEvent<HTMLTextAreaElement>) => {
      if (mentionStartRange && people.length > 0) {
        switch (event.key) {
          case 'ArrowDown': {
            event.preventDefault()
            const previousIndex = index >= people.length - 1 ? 0 : index + 1
            setIndex(previousIndex)
            break
          }
          case 'ArrowUp': {
            event.preventDefault()
            const nextIndex = index <= 0 ? people.length - 1 : index - 1
            setIndex(nextIndex)
            break
          }
          case 'Tab':
          case 'Enter': {
            event.preventDefault()
            const person = people[index]
            if (!person) {
              return
            }
            insertMention(editor, person, mentionStartRange)
            setMentionStartRange(undefined)
            break
          }
          case 'Escape': {
            event.preventDefault()
            setMentionStartRange(undefined)
            break
          }
        }
        return
      }
      onKeyDown(event)
    },
    [people, editor, index, mentionStartRange, onKeyDown]
  )

  const domRange = mentionStartRange
    ? ReactEditor.toDOMRange(editor, mentionStartRange)
    : undefined

  const rect = domRange?.getBoundingClientRect?.()

  const handleChange = useCallback(
    (value: Descendant[]) => {
      const { selection } = editor

      const isAstChange = editor.operations.some(
        (op) => op.type !== 'set_selection'
      )
      if (isAstChange) {
        const parsedValue = parseMention(value)
        onChange(parsedValue)
      }

      if (selection && Range.isCollapsed(selection)) {
        if (mentionStartRange) {
          /* Determine if the mention should be dismissed */
          /* Dismiss mention if the text at the mention start is not '@' or doesn't have any characters */
          const mentionStartText = Editor.string(editor, mentionStartRange)
          const isMentionStartValid = mentionStartText.startsWith('@')

          if (!isMentionStartValid) {
            setMentionStartRange(undefined)
            return
          }

          const searchRange = Editor.range(
            editor,
            Editor.end(editor, mentionStartRange),
            selection
          )
          const searchText = Editor.string(editor, searchRange)
          const fullNameWords = searchText.split(' ').slice(0, -1).join(' ')
          const fullMatches = (users ?? []).filter((user) =>
            user.name.toLowerCase().startsWith(fullNameWords.toLowerCase())
          )

          if (fullMatches.length === 1 && fullMatches[0]) {
            const fullMatch = fullMatches[0]
            const hasAtLeastGivenName = searchText.split(' ').length > 1

            if (hasAtLeastGivenName) {
              insertMention(editor, fullMatch, mentionStartRange)
              setMentionStartRange(undefined)
              setSearch('')
              return
            }
          }

          const searchResult = fuse.search(searchText)
          /* Dismiss the mention if there is no match */
          if (searchText.length > 0 && searchResult.length === 0) {
            setMentionStartRange(undefined)
            setSearch('')
            return
          }

          /* Update search */
          setSearch(searchText)
        } else {
          const mentionStart = detectMentionStart(editor, selection)
          if (!mentionStart) {
            return
          }

          setTimeout(() => {
            setMentionStartRange(mentionStart)
            setSearch('')
          }, 0)
        }
      }
    },
    [editor, fuse, mentionStartRange, onChange, users]
  )

  const handleMouseDown = useCallback(() => {
    setMentionStartRange(undefined)
    setSearch('')
  }, [])

  const reference = useClickOutside<HTMLDivElement>((event) => {
    if (mentionMenuReference.current?.contains(event.target as Node)) {
      return
    }
    setMentionStartRange(undefined)
    setSearch('')
  })

  useEffect(() => {
    ReactEditor.focus(editor)
  }, [editor])

  // This is a hack to make the editor available in the test environment
  if (process.env.NODE_ENV === 'test') {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    globalThis.__TEST_SLATE_EDITOR__ = editor
  }

  return (
    <Slate editor={editor} initialValue={initialValue} onChange={handleChange}>
      <Editable
        data-testid="chat-message-input"
        renderElement={renderElement}
        renderLeaf={renderLeaf}
        onKeyDown={
          handleKeyDown as unknown as KeyboardEventHandler<HTMLDivElement>
        }
        placeholder="Write a message..."
        className="flex-1 caret-sky-500 outline-none"
        style={editorStyle}
        onMouseDown={handleMouseDown}
        ref={reference}
      />
      {mentionStartRange && people.length > 0 && (
        <Portal>
          <MentionMenu
            ref={mentionMenuReference}
            targetRect={rect}
            people={people}
            editor={editor}
            setTarget={setMentionStartRange}
            target={mentionStartRange}
            index={index}
          />
        </Portal>
      )}
    </Slate>
  )
}

ChatMessageInputWithMentions.displayName = 'ChatMessageInputWithMentions'
