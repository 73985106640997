import { IconComponent } from '@/lib/types'

export const TrashIcon: IconComponent = ({ ...properties }) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...properties}
    >
      <path
        d="M11.9991 3.66699C10.5364 3.66699 9.32113 4.75857 9.11496 6.16699H6.26557C6.22868 6.16048 6.19396 6.15831 6.15706 6.15831C6.12668 6.15831 6.0963 6.16265 6.06592 6.16699H4.70741C4.48172 6.16482 4.27338 6.28201 4.15837 6.47732C4.04552 6.67263 4.04552 6.91135 4.15837 7.10666C4.27338 7.30198 4.48172 7.41916 4.70741 7.41699H5.59934L6.64752 18.2633C6.76036 19.4309 7.75429 20.3337 8.92833 20.3337H15.0698C16.2439 20.3337 17.2378 19.4331 17.3506 18.2633L18.3988 7.41699H19.2907C19.5164 7.41916 19.7248 7.30198 19.8398 7.10666C19.9526 6.91135 19.9526 6.67263 19.8398 6.47732C19.7248 6.28201 19.5164 6.16482 19.2907 6.16699H17.9344C17.8671 6.15614 17.7999 6.15614 17.7348 6.16699H14.8832C14.677 4.75857 13.4618 3.66699 11.9991 3.66699ZM11.9991 4.91699C12.7825 4.91699 13.4249 5.44434 13.6072 6.16699H10.391C10.5733 5.44434 11.2157 4.91699 11.9991 4.91699ZM6.85368 7.41699H17.1445L16.105 18.1418C16.0529 18.6822 15.6102 19.0837 15.0698 19.0837H8.92833C8.38797 19.0837 7.94309 18.68 7.89101 18.1418L6.85368 7.41699ZM10.5299 9.49165C10.187 9.49599 9.91141 9.78027 9.91574 10.1253V16.3753C9.91357 16.601 10.0308 16.8094 10.2261 16.9244C10.4214 17.0372 10.6601 17.0372 10.8554 16.9244C11.0507 16.8094 11.1679 16.601 11.1657 16.3753V10.1253C11.1679 9.95605 11.1028 9.79329 10.9835 9.67394C10.8619 9.55458 10.6992 9.48948 10.5299 9.49165ZM13.4466 9.49165C13.1037 9.49599 12.8281 9.78027 12.8324 10.1253V16.3753C12.8302 16.601 12.9474 16.8094 13.1427 16.9244C13.3381 17.0372 13.5768 17.0372 13.7721 16.9244C13.9674 16.8094 14.0846 16.601 14.0824 16.3753V10.1253C14.0846 9.95605 14.0195 9.79329 13.9001 9.67394C13.7786 9.55458 13.6158 9.48948 13.4466 9.49165Z"
        fill="currentColor"
      />
    </svg>
  )
}

TrashIcon.displayName = 'TrashIcon'
