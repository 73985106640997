export interface HighlightableListState {
  highlightCursor: string | undefined
  highlightRange: number
  items: string[]
}

export const highlightableListSelectorsFactory = (
  state: HighlightableListState
) => {
  const Selectors = {
    getHighlightStartIndex: (): number => {
      return state.highlightCursor
        ? state.items.indexOf(state.highlightCursor)
        : -1
    },

    getHighlightedItems: (): string[] => {
      const highlightStartIndex = Selectors.getHighlightStartIndex()

      const minSelectionIndex =
        state.highlightRange >= 0
          ? highlightStartIndex
          : highlightStartIndex + state.highlightRange

      const maxSelectionIndex =
        state.highlightRange >= 0
          ? highlightStartIndex + state.highlightRange
          : highlightStartIndex

      return state.items.slice(minSelectionIndex, maxSelectionIndex + 1)
    },

    getIndexInSelection: (id: string): number => {
      return Selectors.getHighlightedItems().indexOf(id)
    },

    getItemAfterHighlightStart: (): string | undefined => {
      return state.items[Selectors.getItemIndexAfterHighlightStart()]
    },

    getItemBeforeHighlightStart: (): string | undefined => {
      return state.items[Selectors.getItemIndexBeforeHighlightStart()]
    },

    getItemIndex: (id: string): number => {
      return state.items.indexOf(id)
    },

    getItemIndexAfterHighlightStart: (): number => {
      const highlightStartIndex = Selectors.getHighlightStartIndex()
      if (highlightStartIndex === -1) {
        return -1
      }
      return Math.min(highlightStartIndex + 1, state.items.length - 1)
    },

    getItemIndexBeforeHighlightStart: (): number => {
      const highlightStartIndex = Selectors.getHighlightStartIndex()
      if (highlightStartIndex === -1) {
        return -1
      }
      return Math.max(highlightStartIndex - 1, 0)
    },

    getNextItem: (id: string): string | undefined => {
      const index = Selectors.getItemIndex(id)
      if (index === -1 || index === state.items.length - 1) {
        return undefined
      }
      return state.items[index + 1]
    },

    getPreviousItem: (id: string): string | undefined => {
      const index = Selectors.getItemIndex(id)
      if (index === -1 || index === 0) {
        return undefined
      }
      return state.items[index - 1]
    },

    isHighlightCursor: (id: string): boolean => {
      return state.highlightCursor === id
    },

    isHighlighted: (id: string): boolean => {
      return Selectors.getIndexInSelection(id) > -1
    },

    isNextHighlighted: (id: string): boolean => {
      const nextItem = Selectors.getNextItem(id)
      if (nextItem === undefined) {
        return false
      }

      return Selectors.isHighlighted(nextItem)
    },

    isPreviousHighlighted: (id: string): boolean => {
      const previousItem = Selectors.getPreviousItem(id)
      if (previousItem === undefined) {
        return false
      }

      return Selectors.isHighlighted(previousItem)
    },
  }

  return Selectors
}

export const highlightableListActionHandlers = (
  state: HighlightableListState
) => {
  const selectors = highlightableListSelectorsFactory(state)

  return {
    onArrowDown: (): HighlightableListState => ({
      ...state,
      highlightCursor: selectors.getItemAfterHighlightStart(),
      highlightRange: 0,
    }),

    onArrowUp: (): HighlightableListState => ({
      ...state,
      highlightCursor: selectors.getItemBeforeHighlightStart(),
      highlightRange: 0,
    }),

    onInit: (items: string[]): HighlightableListState => ({
      ...state,
      highlightCursor: undefined,
      highlightRange: 0,
      items,
    }),

    onRealFocusChange: (
      highlightCursor: string | undefined
    ): HighlightableListState => {
      if (highlightCursor === undefined) {
        return {
          ...state,
          highlightCursor: undefined,
          highlightRange: 0,
        }
      }

      return {
        ...state,
        highlightCursor,
        highlightRange: 0,
      }
    },

    onShiftArrowDown: (): HighlightableListState => {
      const highlightStartIndex = selectors.getHighlightStartIndex()

      if (highlightStartIndex === -1) {
        return state
      }

      return {
        ...state,
        highlightRange: Math.min(
          state.items.length - highlightStartIndex - 1,
          state.highlightRange + 1
        ),
      }
    },

    onShiftArrowUp: (): HighlightableListState => {
      const highlightStartIndex = selectors.getHighlightStartIndex()

      if (highlightStartIndex === -1) {
        return state
      }

      return {
        ...state,
        highlightRange: Math.max(
          -highlightStartIndex,
          state.highlightRange - 1
        ),
      }
    },
  }
}
