import { useEffect, useLayoutEffect, useRef } from 'react'

export const useClickOutside = <T extends HTMLElement>(
  callback?: (event: MouseEvent | TouchEvent) => void
) => {
  const targetReference = useRef<T>(null)
  const callbackReference = useRef<typeof callback>(callback)

  useLayoutEffect(() => {
    callbackReference.current = callback
  })

  useEffect(() => {
    const targetNode = targetReference.current

    if (!targetNode) return

    const handleClick = (event: MouseEvent | TouchEvent) => {
      if (!targetNode.contains(event.target as Node)) {
        callbackReference.current?.(event)
      }
    }

    document.addEventListener('click', handleClick, true)
    document.addEventListener('contextmenu', handleClick, true)

    return () => {
      document.removeEventListener('click', handleClick, true)
      document.removeEventListener('contextmenu', handleClick, true)
    }
  }, [])

  return targetReference
}
