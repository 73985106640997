import { useCallback, useState } from 'react'

import { ArrowDownIcon } from '@/components/icons/ArrowDownIcon'
import { CrossLargeIcon } from '@/components/icons/CrossLargeIcon'
import { Avatar } from '@/components/ui/avatar/Avatar'
import { Button } from '@/components/ui/button/Button'
import { Form, FormProperties } from '@/components/ui/form/Form'
import { TextArea } from '@/components/ui/text-area/TextArea'
import { useOIDCAuth } from '@/contexts/OidcAuthContext'

interface FormValues {
  planningFeedback: string
}

export const SubtasksPlanningFeedbackForm = ({
  onCancel,
  onSubmit,
}: {
  onSubmit: (feedback: string) => void
  onCancel: () => void
}) => {
  const [feedback, setFeedback] = useState('')

  const handleChange = useCallback(
    (event: React.ChangeEvent<HTMLTextAreaElement>) => {
      setFeedback(event.target.value)
    },
    []
  )

  const { user } = useOIDCAuth()

  const handleFeedbackSubmit: FormProperties<FormValues>['onSubmit'] =
    useCallback(
      ({ planningFeedback }) => {
        onSubmit(planningFeedback)
      },
      [onSubmit]
    )

  return (
    <Form<FormValues> onSubmit={handleFeedbackSubmit}>
      <div className="flex w-80 flex-col gap-2 p-1">
        <div className="flex items-center gap-1">
          <Avatar className="size-6 rounded-sm" src={user?.profile.picture} />
          <h3 className="text-sm font-medium text-mono-ink-strong">
            {user?.profile.name}
          </h3>
        </div>
        <TextArea
          data-testid="planning-feedback-textarea"
          mode="uncontrolled"
          name="planningFeedback"
          onChange={handleChange}
        />
        <div className="flex justify-end gap-1">
          <Button
            size="sm"
            type="reset"
            className="px-3"
            onClick={onCancel}
            data-testid="cancel-feedback"
          >
            <CrossLargeIcon className="size-5" />
          </Button>
          <Button
            size="sm"
            type="submit"
            className="px-3"
            variant="solid"
            disabled={!feedback.trim()}
            data-testid="submit-feedback"
          >
            <ArrowDownIcon className="rotate-180" />
          </Button>
        </div>
      </div>
    </Form>
  )
}

SubtasksPlanningFeedbackForm.displayName = 'SubtasksPlanningFeedbackForm'
