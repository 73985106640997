import { IconComponent } from '@/lib/types'

export const CaretDownIcon: IconComponent = ({ ...properties }) => {
  return (
    <svg
      fill="none"
      height="16"
      viewBox="0 0 16 16"
      width="16"
      xmlns="http://www.w3.org/2000/svg"
      {...properties}
    >
      <path
        d="M3.93335 6.47559L4.93335 5.47559L7.98196 8.52073L11.0271 5.47559L12.0271 6.47559L7.98196 10.5207L3.93335 6.47559Z"
        fill="currentColor"
      />
    </svg>
  )
}

CaretDownIcon.displayName = 'CaretDownIcon'
