import { useFlag } from '@unleash/proxy-client-react'
import React, { KeyboardEvent, useCallback, useRef, useState } from 'react'

import { AttachClipIcon } from '@/components/icons/AttachClipIcon'
import { MessageBubbleIcon } from '@/components/icons/MessageBubbleIcon'
import { SendIcon } from '@/components/icons/SendIcon'
import { Button } from '@/components/ui/button/Button'
import { ChatMessageInputWithMentions } from '@/features/task/components/chat/input/ChatMessageInputWithMentions'

interface IChatMessageInputProperties {
  onSendMessage: (data: { message: string }) => void
  disabled?: boolean
}

const useChatMessageInput = ({
  onSendMessage,
}: IChatMessageInputProperties) => {
  const [message, setMessage] = useState<string>('')
  const inputReference = useRef<HTMLTextAreaElement>(null)
  const [editorKey, setEditorKey] = useState('')

  const onResetEditorKey = useCallback(() => {
    setEditorKey(Date.now().toString())
  }, [])

  const handleSendClick = useCallback(() => {
    if (message.trim()) {
      onSendMessage({ message: message.trim() })
      setMessage('')
      onResetEditorKey()
      if (inputReference.current) {
        inputReference.current.style.height = 'auto'
      }
    }
  }, [message, onResetEditorKey, onSendMessage])

  const handleKeyPress = useCallback(
    (event: KeyboardEvent<HTMLTextAreaElement>) => {
      if (event.key === 'Escape') {
        event.preventDefault()
        setMessage('')
        onResetEditorKey()
      }
      if (event.key === 'Enter' && !event.shiftKey) {
        event.preventDefault()
        handleSendClick()
        onResetEditorKey()
      }
    },
    [handleSendClick, onResetEditorKey]
  )

  return {
    editorKey,
    handleKeyPress,
    handleSendClick,
    message,
    setMessage,
  }
}

const ChatMessageInputDefault: React.FC<IChatMessageInputProperties> = ({
  disabled = false,
  onSendMessage,
}) => {
  const { editorKey, handleKeyPress, handleSendClick, message, setMessage } =
    useChatMessageInput({ onSendMessage })

  return (
    <div className="flex grow">
      <div className="flex grow flex-row items-end gap-3 rounded-lg bg-white p-3">
        <MessageBubbleIcon />
        <ChatMessageInputWithMentions
          key={editorKey}
          onChange={setMessage}
          onKeyDown={handleKeyPress}
        />
        <Button
          data-testid="button-send-message"
          onClick={handleSendClick}
          size="sm"
          type="submit"
          variant="solid"
          disabled={!message || disabled}
        >
          <SendIcon />
        </Button>
      </div>
    </div>
  )
}

ChatMessageInputDefault.displayName = 'ChatMessageInputDefault'

const ChatMessageInputFileUpload: React.FC<IChatMessageInputProperties> = ({
  disabled = false,
  onSendMessage,
}) => {
  const { editorKey, handleKeyPress, handleSendClick, message, setMessage } =
    useChatMessageInput({ onSendMessage })

  return (
    <div className="rounded-lg bg-white p-3">
      <ChatMessageInputWithMentions
        key={editorKey}
        onChange={setMessage}
        onKeyDown={handleKeyPress}
      />
      <div className="flex flex-row items-center justify-end gap-3">
        <Button
          asChild
          variant="naked"
          size="sm"
          className="h-8 w-8 cursor-pointer p-0"
        >
          <label>
            <input type="file" className="hidden" />
            <AttachClipIcon />
          </label>
        </Button>
        <Button
          data-testid="button-send-message"
          onClick={handleSendClick}
          size="sm"
          className="h-8 w-8 p-0"
          type="submit"
          variant="solid"
          disabled={!message || disabled}
        >
          <SendIcon />
        </Button>
      </div>
    </div>
  )
}

ChatMessageInputFileUpload.displayName = 'ChatMessageInputFileUpload'

export const ChatMessageInput = (properties: IChatMessageInputProperties) => {
  const fileUploadEnabled = useFlag('pro-1247-ability-to-post-images-in-thread')
  return fileUploadEnabled ? (
    <ChatMessageInputFileUpload {...properties} />
  ) : (
    <ChatMessageInputDefault {...properties} />
  )
}

ChatMessageInput.displayName = 'ChatMessageInput'
