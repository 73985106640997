import { IconComponent } from '@/lib/types'

export const CheckboxIcon: IconComponent = ({ ...properties }) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...properties}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8 3C5.23858 3 3 5.23858 3 8V16C3 18.7614 5.23858 21 8 21H16C18.7614 21 21 18.7614 21 16V8C21 5.23858 18.7614 3 16 3H8ZM17.0498 8.06222C17.4309 8.38129 17.4812 8.94892 17.1622 9.33004L11.3059 16.3254C11.1411 16.5222 10.9 16.6393 10.6434 16.6472C10.3868 16.6551 10.1391 16.553 9.96247 16.3666L6.87462 13.1077C6.53275 12.7469 6.5481 12.1773 6.90891 11.8354C7.26973 11.4935 7.83937 11.5089 8.18124 11.8697L10.5742 14.3952L15.782 8.17459C16.101 7.79346 16.6687 7.74315 17.0498 8.06222Z"
        fill="currentColor"
      />
    </svg>
  )
}

CheckboxIcon.displayName = 'CheckboxIcon'
