import { useEffect } from 'react'

export const usePasteEvent = (callback: (event: ClipboardEvent) => void) => {
  useEffect(() => {
    document.addEventListener('paste', callback, true)

    return () => {
      document.removeEventListener('paste', callback, true)
    }
  }, [callback])
}
