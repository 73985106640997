import { Editor } from 'slate'

export const withMentions = (editor: Editor) => {
  const { isInline, isVoid, markableVoid } = editor

  editor.isInline = (element) => {
    return 'type' in element && element.type === 'mention'
      ? true
      : isInline(element)
  }

  editor.isVoid = (element) => {
    return 'type' in element && element.type === 'mention'
      ? true
      : isVoid(element)
  }

  editor.markableVoid = (element) => {
    return (
      ('type' in element && element.type === 'mention') || markableVoid(element)
    )
  }

  return editor
}
