import { IconComponent } from '@/lib/types'

export const AttachClipIcon: IconComponent = ({ ...properties }) => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...properties}
    >
      <path
        d="M13.5114 1.68185C12.3331 1.68185 11.1525 2.1289 10.2584 3.02517L1.84911 11.4323C1.68635 11.5907 1.62125 11.8229 1.67767 12.0421C1.7341 12.2613 1.90554 12.4327 2.12472 12.4891C2.3439 12.5456 2.57611 12.4805 2.73453 12.3177L11.1416 3.91059C12.4546 2.59548 14.5683 2.59548 15.8834 3.91059C17.1963 5.22352 17.1963 7.33724 15.8834 8.65017L7.9472 16.5864C7.26578 17.2678 6.18071 17.2678 5.49712 16.5864C4.81569 15.9028 4.81569 14.8177 5.49712 14.1363L11.9012 7.73437C12.064 7.57595 12.1291 7.34375 12.0726 7.12456C12.0162 6.90538 11.8448 6.73394 11.6256 6.67751C11.4064 6.62109 11.1742 6.68619 11.0158 6.84896L4.61387 13.253C3.45502 14.4119 3.45502 16.3108 4.61387 17.4696C5.77272 18.6285 7.6716 18.6285 8.83045 17.4696L16.7666 9.53342C18.5592 7.74305 18.5592 4.8177 16.7666 3.02517C15.8704 2.1289 14.6898 1.68185 13.5114 1.68185Z"
        fill="currentColor"
      />
    </svg>
  )
}

AttachClipIcon.displayName = 'AttachClipIcon'
