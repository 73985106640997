import { FC } from 'react'

import { CheckedCircleSolidIcon } from '@/components/icons/CheckedCircleSolidIcon'
import { CircleCrossFilledIcon } from '@/components/icons/CircleCrossFilledIcon'
import { TaskCircularCheckboxEmptyIcon } from '@/components/icons/TaskCircularCheckboxEmptyIcon'
import { cn } from '@/lib/utils'
import { TaskStatus } from '@/tests/generated-api-types'

export interface Properties {
  className?: string
  status?: TaskStatus
  size?: 'md' | 'lg' | 'sm'
}

const taskStatusIcons: Record<
  TaskStatus,
  React.ComponentType<{ className?: string }>
> = {
  CANCELLED: CircleCrossFilledIcon,
  COMPLETED: CheckedCircleSolidIcon,
  OPEN: TaskCircularCheckboxEmptyIcon,
}

export const TaskIcon: FC<Properties> = ({
  className,
  size = 'lg',
  status,
}) => {
  const sizeClass = cn({
    'size-4 min-w-4 min-h-4': size === 'sm',
    'size-6 min-w-6 min-h-6': size === 'md',
    'size-8 min-w-8 min-h-8': size === 'lg',
  })

  const IconComponent = status
    ? taskStatusIcons[status]
    : TaskCircularCheckboxEmptyIcon

  return <IconComponent className={cn(sizeClass, className)} />
}
TaskIcon.displayName = 'TaskIcon'
