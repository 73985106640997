import { Content } from '@radix-ui/react-popover'
import { useNavigate } from '@tanstack/react-router'
import {
  ComponentPropsWithoutRef,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react'
import { createPortal } from 'react-dom'

import { ThreeDotsVerticalIcon } from '@/components/icons/ThreeDotsVerticalIcon'
import { TrashIcon } from '@/components/icons/TrashIcon'
import { Button } from '@/components/ui/button/Button'
import { Casing } from '@/components/ui/casing/Casing'
import * as PopoverPrimitive from '@/components/ui/popover/Popover'
import { DeleteTasksDialog } from '@/features/task/components/DeleteTaskDialog/DeleteTasksDialog'
import { useDeleteTask } from '@/features/task/hooks/useDeleteTask'
import { useBoolean } from '@/lib/hooks/useBoolean'
import { cn } from '@/lib/utils'

interface PopoverProperties {
  taskId: string
  parentTaskId?: string
}

export const DeleteTaskPopover = ({
  parentTaskId,
  taskId,
}: PopoverProperties) => {
  const itemsToDelete = useMemo(() => [{ id: taskId }], [taskId])
  const [breadcrumbsPortal, setBreadcrumbsPortal] = useState<Element | null>(
    document.querySelector('#breadcrumbsPortal')
  )
  const navigate = useNavigate()

  const deleteTaskMutation = useDeleteTask({
    onError: () => {
      void (async () => {
        await navigate({
          params: { taskId },
          replace: true,
          to: `/all-work/$taskId`,
        })
      })()
    },
    onMutate: () => {
      void (async () => {
        await (parentTaskId
          ? navigate({
              params: { taskId: parentTaskId },
              replace: true,
              to: '/all-work/$taskId',
            })
          : navigate({ replace: true, to: '..' }))
      })()
    },
  })

  const commandPopoverControl = useBoolean(false)
  const confirmDialogControl = useBoolean(false)

  const handleTaskCommandPopoverEsc = (event: KeyboardEvent) => {
    event.preventDefault()
    commandPopoverControl.setFalse()
  }

  const handleTaskCommandPopoverPointerDownOutside: ComponentPropsWithoutRef<
    typeof Content
  >['onPointerDownOutside'] = (event) => {
    event.preventDefault()
    commandPopoverControl.setFalse()
  }

  const deleteTask = useCallback(() => {
    deleteTaskMutation.mutate(taskId)
  }, [deleteTaskMutation, taskId])

  const handleConfirmationDialogOpen = useCallback(
    (open: boolean) => {
      if (open) {
        confirmDialogControl.setTrue()
      } else {
        commandPopoverControl.setFalse()
        confirmDialogControl.setFalse()
      }
    },
    [commandPopoverControl, confirmDialogControl]
  )

  useEffect(() => {
    setBreadcrumbsPortal(document.querySelector('#breadcrumbsPortal'))
  }, [])

  return (
    breadcrumbsPortal &&
    createPortal(
      <Casing className="grow items-center" size="large" variant="naked">
        <PopoverPrimitive.Popover
          onOpenChange={commandPopoverControl.setTrue}
          open={commandPopoverControl.value}
        >
          <PopoverPrimitive.PopoverTrigger
            className="flex items-center gap-1 rounded-sm border border-transparent p-1 hover:border-film-subtle hover:bg-film-subtle focus-visible:border-film-subtle focus-visible:bg-film-subtle active:bg-film-stronger"
            data-testid="task-actions-trigger"
          >
            <ThreeDotsVerticalIcon />
          </PopoverPrimitive.PopoverTrigger>
          <PopoverPrimitive.PopoverPortal>
            <PopoverPrimitive.PopoverContent
              onPointerDownOutside={handleTaskCommandPopoverPointerDownOutside}
              onEscapeKeyDown={handleTaskCommandPopoverEsc}
              useTriggerWidth={false}
              align="end"
              className={cn('min-w-40', {
                hidden:
                  commandPopoverControl.value && confirmDialogControl.value,
              })}
            >
              <DeleteTasksDialog
                itemsToDelete={itemsToDelete}
                onDeleteClick={deleteTask}
                onOpenChange={handleConfirmationDialogOpen}
              >
                <Button
                  data-testid="delete-task-button"
                  className="flex h-7 min-h-7 w-full min-w-9 items-center justify-start gap-1 rounded-sm border border-transparent p-1 hover:border-film-subtle hover:bg-film-subtle hover:shadow-none focus:shadow-none focus-visible:border-film-subtle focus-visible:bg-film-subtle active:bg-film-stronger"
                  variant={'naked'}
                >
                  <TrashIcon className="size-5 min-h-5 min-w-5" />
                  Delete
                </Button>
              </DeleteTasksDialog>
            </PopoverPrimitive.PopoverContent>
          </PopoverPrimitive.PopoverPortal>
        </PopoverPrimitive.Popover>
      </Casing>,
      breadcrumbsPortal
    )
  )
}

DeleteTaskPopover.displayName = 'DeleteTaskPopover'
