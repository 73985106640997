import { IconComponent } from '@/lib/types'

export const UnassignedIcon: IconComponent = ({ ...properties }) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...properties}
  >
    <path
      d="M12 6C11.0625 6 10.2656 6.3776 9.7526 6.95573C9.23958 7.53125 9 8.27083 9 9C9 9.72917 9.23958 10.4688 9.7526 11.0443C10.2656 11.6224 11.0625 12 12 12C12.9375 12 13.7344 11.6224 14.2474 11.0443C14.7604 10.4688 15 9.72917 15 9C15 8.27083 14.7604 7.53125 14.2474 6.95573C13.7344 6.3776 12.9375 6 12 6ZM8.63021 13C7.73958 13 7 13.7396 7 14.6302V15.1719C7 16.1641 7.6276 17.0286 8.52344 17.599C9.41927 18.1693 10.6146 18.5 12 18.5C13.3854 18.5 14.5807 18.1693 15.4766 17.599C16.3724 17.0286 17 16.1641 17 15.1719V14.6302C17 13.7396 16.2604 13 15.3698 13H8.63021Z"
      fill="currentColor"
    />
    <circle
      cx="12"
      cy="12"
      r="9.25"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeDasharray="2 1.5"
    />
  </svg>
)

UnassignedIcon.displayName = 'UnassignedIcon'
