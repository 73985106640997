import { IconComponent } from '@/lib/types'

export const CopyLinkToClipboardIcon: IconComponent = ({ ...properties }) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...properties}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.5417 3.25C9.58468 3.25 8.79475 3.98568 8.68841 4.91667H7.20837C6.17973 4.91667 5.33337 5.76302 5.33337 6.79167V18.0417C5.33337 19.0703 6.17973 19.9167 7.20837 19.9167H8.68841V18.6667H7.20837C6.85464 18.6667 6.58337 18.3954 6.58337 18.0417V6.79167C6.58337 6.43793 6.85464 6.16667 7.20837 6.16667H8.98789C9.32643 6.66797 9.89718 7 10.5417 7H13.4584C14.1029 7 14.6737 6.66797 15.0122 6.16667H16.7917C17.1454 6.16667 17.4167 6.43793 17.4167 6.79167V12.625H18.6667V6.79167C18.6667 5.76302 17.8204 4.91667 16.7917 4.91667H15.3117C15.2053 3.98568 14.4154 3.25 13.4584 3.25H10.5417ZM13.4584 4.5H10.5417C10.188 4.5 9.91671 4.77127 9.91671 5.125C9.91671 5.47873 10.188 5.75 10.5417 5.75H13.4584C13.8121 5.75 14.0834 5.47873 14.0834 5.125C14.0834 4.77127 13.8121 4.5 13.4584 4.5Z"
        fill="currentColor"
      />
      <path
        d="M12.625 13.6667C10.902 13.6667 9.50004 15.0686 9.50004 16.7917C9.50004 18.5148 10.902 19.9167 12.625 19.9167H13.4584C13.8034 19.9167 14.0834 19.6367 14.0834 19.2917C14.0834 18.9466 13.8034 18.6667 13.4584 18.6667H12.625C11.5921 18.6667 10.75 17.8247 10.75 16.7917C10.75 15.7587 11.5921 14.9167 12.625 14.9167H13.4584C13.8034 14.9167 14.0834 14.6367 14.0834 14.2917C14.0834 13.9466 13.8034 13.6667 13.4584 13.6667H12.625ZM16.375 13.6667C16.03 13.6667 15.75 13.9466 15.75 14.2917C15.75 14.6367 16.03 14.9167 16.375 14.9167H17.2084C18.2414 14.9167 19.0834 15.7587 19.0834 16.7917C19.0834 17.8247 18.2414 18.6667 17.2084 18.6667H16.375C16.03 18.6667 15.75 18.9466 15.75 19.2917C15.75 19.6367 16.03 19.9167 16.375 19.9167H17.2084C18.9315 19.9167 20.3334 18.5148 20.3334 16.7917C20.3334 15.0686 18.9315 13.6667 17.2084 13.6667H16.375ZM12.625 16.1667C12.28 16.1667 12 16.4466 12 16.7917C12 17.1367 12.28 17.4167 12.625 17.4167H17.2084C17.5534 17.4167 17.8334 17.1367 17.8334 16.7917C17.8334 16.4466 17.5534 16.1667 17.2084 16.1667H12.625Z"
        fill="currentColor"
      />
    </svg>
  )
}

CopyLinkToClipboardIcon.displayName = 'CopyLinkToClipboardIcon'
