import { forwardRef } from 'react'
import { useMeasure } from 'react-use'
import { Editor, Range } from 'slate'
import { ReactEditor } from 'slate-react'

import { Avatar } from '@/components/ui/avatar/Avatar'
import { insertMention } from '@/features/task/components/chat/input/utils'
import { cn, mergeRefs } from '@/lib/utils'

interface MentionMenuProperties {
  targetRect?: DOMRect
  people: Array<{ id: string; name: string; avatarUrl?: string | null }>
  editor: Editor
  setTarget: (target: Range | undefined) => void
  target: Range | undefined
  index: number
}

export const MentionMenu = forwardRef<HTMLDivElement, MentionMenuProperties>(
  (
    { editor, index, people, setTarget, target, targetRect },
    outerReference
  ) => {
    const [reference, { height }] = useMeasure<HTMLDivElement>()
    const top = targetRect?.top ? targetRect.top - height - 8 : '-9999px'

    return (
      <div
        ref={mergeRefs(outerReference, reference)}
        style={{
          left: targetRect?.left ?? '-9999px',
          top,
        }}
        className="absolute z-50 min-w-[262px] rounded-lg bg-mono-paper p-1 shadow-just-shadow"
        data-testid="mention-menu"
      >
        <h4 className="my-1 block px-1 text-xs font-medium leading-4 text-mono-ink-subtle">
          People
        </h4>
        <div className="flex max-h-[70vh] grow flex-col gap-1 overflow-y-auto">
          {people.map((person, index_) => (
            <div
              key={person.id}
              onClick={() => {
                if (!target) {
                  return
                }
                insertMention(editor, person, target)
                setTarget(undefined)
                setTimeout(() => {
                  ReactEditor.focus(editor)
                }, 0)
              }}
              className={cn(
                'flex cursor-pointer items-center gap-1 rounded-md border border-transparent p-1 hover:border-film-normal',
                {
                  'rounded-md border-sky-50 bg-blue-ink-strong text-sm-regular text-mono-paper':
                    index_ === index,
                }
              )}
            >
              <Avatar src={person.avatarUrl} className="h-6 w-6 rounded-full" />
              {person.name}
            </div>
          ))}
        </div>
      </div>
    )
  }
)

MentionMenu.displayName = 'MentionMenu'
