import { useMutation, useQueryClient } from '@tanstack/react-query'
import { useNavigate } from '@tanstack/react-router'
import { toast } from 'react-toastify'
import { v7 as uuid } from 'uuid'

import { useApi } from '@/contexts/ApiProvider'
import { useNewTaskContext } from '@/contexts/NewTaskProvider'
import { FetchTasksByIdQuery } from '@/gql/generated/graphql'
import { logger } from '@/lib/logger'

interface UseCreateTaskProperties {
  taskId?: string
  onMutate?: () => void
}

type Task = FetchTasksByIdQuery['tasks'][number]

export const useCreateTask = (properties?: UseCreateTaskProperties) => {
  const navigate = useNavigate()
  const { dispatch } = useNewTaskContext()
  const api = useApi()
  const queryClient = useQueryClient()

  const queryKey = properties?.taskId ? ['task', properties.taskId] : ['task']

  const createTaskMutation = useMutation({
    mutationFn: api.createTask,
    onError: (error) => {
      logger.error(error)
      toast.error(error.message)
    },
    onMutate: properties?.taskId
      ? async (payload) => {
          const previousState = queryClient.getQueryData<Task>(queryKey)

          queryClient.setQueryData<Task>(queryKey, (previousState) => {
            /* v8 ignore next */
            if (!previousState) return

            return {
              ...previousState,
              subtasks: [
                ...previousState.subtasks,
                {
                  createdAt: new Date().toISOString(),
                  id: uuid(),
                  status: 'OPEN',
                  ...payload,
                },
              ],
            }
          })

          properties?.onMutate?.()

          return { previousState }
        }
      : undefined,
    onSettled: async () => {
      await queryClient.invalidateQueries({
        queryKey,
      })
    },
    onSuccess: properties?.taskId
      ? undefined
      : ({ id }) => {
          dispatch({ type: 'CLEAR' })

          void navigate({
            params: {
              taskId: id,
            },
            to: '/all-work/$taskId',
          })
        },
  })

  return {
    createTask: createTaskMutation.mutate,
    isCreatingTask: createTaskMutation.isPending,
  }
}
