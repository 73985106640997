import {
  Dispatch,
  Reducer,
  useCallback,
  useEffect,
  useMemo,
  useReducer,
  useRef,
} from 'react'

import {
  highlightableListSelectorsFactory,
  HighlightableListState,
} from '@/lib/hooks/use-highlightable-list/state'
import { useDocumentKeyCapture } from '@/lib/hooks/useDocumentKeyCapture'

type Action =
  | {
      type: 'onArrowDown' | 'onArrowUp' | 'onShiftArrowDown' | 'onShiftArrowUp'
    }
  | {
      type: 'onRealFocusChange'
      payload: string | undefined
    }

export const useHighlightableListItem = ({
  blurSuspended,
  dispatch,
  id,
  isArrowListenerActive,
  onArrowUpDown,
  state,
}: {
  dispatch: Dispatch<Action>
  onArrowUpDown?: (event: KeyboardEvent) => boolean
  blurSuspended: boolean
  state: HighlightableListState
  isArrowListenerActive: boolean
  id: string
}) => {
  const { items } = state
  const localReference = useRef<HTMLDivElement>(null)
  const selectors = highlightableListSelectorsFactory(state)
  const isHighlightCursor = selectors.isHighlightCursor(id)
  const isHighlighted = selectors.isHighlighted(id)
  const highlightCursorIndex = selectors.getHighlightStartIndex()
  const highligtedItems = selectors.getHighlightedItems()
  const combinedIsArrowListenerActive =
    isHighlightCursor && isArrowListenerActive

  const handleArrowUpDown = useCallback(
    ({ event }: { event: KeyboardEvent }) => {
      if (
        !(event.key === 'ArrowUp' || event.key === 'ArrowDown') ||
        highlightCursorIndex < 0
      ) {
        return
      }
      event.preventDefault()

      if (onArrowUpDown?.(event)) {
        return
      }

      if (event.key === 'ArrowUp') {
        if (event.shiftKey) {
          dispatch({ type: 'onShiftArrowUp' })
        } else if (highlightCursorIndex > 0) {
          dispatch({ type: 'onArrowUp' })
        }
      }

      if (event.key === 'ArrowDown') {
        if (event.shiftKey) {
          dispatch({ type: 'onShiftArrowDown' })
        } else if (highlightCursorIndex < items.length - 1) {
          dispatch({ type: 'onArrowDown' })
        }
      }
    },
    [dispatch, highlightCursorIndex, items.length, onArrowUpDown]
  )

  useDocumentKeyCapture(
    'ArrowDown',
    handleArrowUpDown,
    combinedIsArrowListenerActive
  )
  useDocumentKeyCapture(
    'ArrowUp',
    handleArrowUpDown,
    combinedIsArrowListenerActive
  )

  useEffect(() => {
    const isRealFocusWithinCurrentElement = localReference.current?.contains(
      document.activeElement
    )
    if (
      isHighlightCursor &&
      !isRealFocusWithinCurrentElement &&
      !blurSuspended
    ) {
      localReference.current?.focus()
    }
  }, [isHighlightCursor, blurSuspended])

  const handleFocus = useCallback(() => {
    dispatch({ payload: id, type: 'onRealFocusChange' })
  }, [dispatch, id])

  const handleBlur = useCallback(() => {
    if (!blurSuspended) {
      dispatch({ payload: undefined, type: 'onRealFocusChange' })
    }
  }, [dispatch, blurSuspended])

  const handleClick = useCallback(() => {
    dispatch({ payload: id, type: 'onRealFocusChange' })
  }, [dispatch, id])

  return useMemo(
    () => ({
      highligtedItems,
      isHighlighted,
      onBlur: handleBlur,
      onClick: handleClick,
      onFocus: handleFocus,
      ref: localReference,
      selectors,
    }),
    [
      handleBlur,
      handleClick,
      handleFocus,
      highligtedItems,
      isHighlighted,
      localReference,
      selectors,
    ]
  )
}

export const useHighlightableList = <I, S extends HighlightableListState, A>({
  initialState,
  listItems,
  reducer,
}: {
  listItems: I[]
  reducer: Reducer<S, A>
  initialState: S
}) => {
  const [state, dispatch] = useReducer(reducer, initialState)
  const previousListItems = useRef(listItems)

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
    dispatch({ payload: previousListItems.current, type: 'onInit' } as A)
  }, [])

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
    dispatch({ payload: listItems, type: 'onListUpdate' } as A)
  }, [listItems])

  return {
    dispatch,
    state,
  }
}
