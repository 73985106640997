import { IconComponent } from '@/lib/types'

export const RadarIcon: IconComponent = ({ ...properties }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      fill="none"
      viewBox="0 0 20 20"
      {...properties}
    >
      <g>
        <path
          fill="currentColor"
          d="M10 1.667c-4.594 0-8.333 3.739-8.333 8.333S5.406 18.333 10 18.333s8.333-3.739 8.333-8.333S14.594 1.667 10 1.667zm0 1.25c.981 0 1.916.2 2.765.557l-.81 1.535A5.374 5.374 0 0010 4.637c-.69 0-1.348.135-1.955.372l-.81-1.535A7.1 7.1 0 0110 2.916zm-3.867 1.15l.807 1.525a5.41 5.41 0 00-2.357 4.462A5.426 5.426 0 0010 15.471a5.426 5.426 0 005.417-5.417 5.41 5.41 0 00-2.357-4.462l.807-1.525A7.062 7.062 0 0117.083 10 7.074 7.074 0 0110 17.083 7.074 7.074 0 012.917 10a7.062 7.062 0 013.216-5.933zM10 5.887c.48 0 .94.085 1.37.233l-.816 1.547c-.185-.04-.352-.113-.554-.113-.202 0-.369.074-.553.113L8.63 6.12c.43-.148.89-.233 1.37-.233zm-2.474.816L8.32 8.21c-.056.055-.14.083-.193.14a2.559 2.559 0 00-.627 1.705c0 .608.2 1.224.627 1.704.428.481 1.092.796 1.873.796.781 0 1.445-.315 1.873-.796a2.55 2.55 0 00.627-1.704c0-.608-.2-1.224-.627-1.706-.052-.056-.137-.084-.193-.139l.794-1.506a4.15 4.15 0 011.693 3.351A4.157 4.157 0 0110 14.221a4.157 4.157 0 01-4.167-4.167 4.15 4.15 0 011.693-3.35zM10 8.804c.469 0 .742.154.94.376.197.223.31.544.31.874 0 .33-.113.651-.31.873-.198.223-.471.377-.94.377s-.742-.154-.94-.377a1.326 1.326 0 01-.31-.873c0-.33.113-.65.31-.874.198-.222.471-.376.94-.376z"
        ></path>
      </g>
    </svg>
  )
}

RadarIcon.displayName = 'RadarIcon'
