import { Editor, Transforms, Location, Descendant } from 'slate'

import { MentionElement } from '@/features/task/components/chat/input/types'

export const insertMention = (
  editor: Editor,
  person: { id: string; name: string },
  target: Location
) => {
  const mention: MentionElement = {
    children: [{ text: '' }],
    fullName: person.name,
    id: person.id,
    type: 'mention',
  }
  const { selection } = editor
  if (!selection) {
    return
  }
  const replacementRange = Editor.range(
    editor,
    Editor.start(editor, target),
    Editor.end(editor, selection)
  )

  Transforms.insertText(editor, ' ', {
    at: Editor.end(editor, selection),
  })
  Transforms.insertNodes(editor, mention, { at: replacementRange })
}

const parseNode = (node: Descendant): string => {
  if ('type' in node) {
    return `<@U${node.id}>`
  }
  if ('children' in node) {
    return node.children.map((subNode) => parseNode(subNode)).join('')
  }
  return node.text
}

export const parseMention = (value: Descendant[]) =>
  value.map((node) => parseNode(node)).join('\n')

export const detectMentionStart = (editor: Editor, selection: Location) => {
  /* First of all we need to detect '@' character being typed */
  const previousTwoCharactersStartPoint = Editor.before(editor, selection, {
    distance: 2,
    unit: 'character',
  })
  const previousTwoCharactersRange =
    previousTwoCharactersStartPoint &&
    Editor.range(editor, previousTwoCharactersStartPoint, selection)
  const previousTwoCharactersText =
    previousTwoCharactersRange &&
    Editor.string(editor, previousTwoCharactersRange)

  const isMentionStart =
    previousTwoCharactersText?.at(-1) === '@' &&
    (previousTwoCharactersText.length === 1 ||
      previousTwoCharactersText.at(-2)?.match(/^[\s]/))

  if (!isMentionStart) {
    return
  }
  const mentionStartPoint = Editor.before(editor, selection, {
    unit: 'character',
  })

  if (!mentionStartPoint) {
    return
  }

  const mentionStartRange = Editor.range(editor, mentionStartPoint, selection)

  return mentionStartRange
}
