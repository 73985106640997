import { IconComponent } from '@/lib/types'

export const PlusCircleIcon: IconComponent = ({ ...properties }) => {
  return (
    <svg
      fill="none"
      height="24"
      viewBox="0 0 24 24"
      width="24"
      xmlns="http://www.w3.org/2000/svg"
      {...properties}
    >
      <path
        d="M12 2C6.48958 2 2 6.48958 2 12C2 17.5104 6.48958 22 12 22C17.5104 22 22 17.5104 22 12C22 6.48958 17.5104 2 12 2ZM12 4C16.4323 4 20 7.56771 20 12C20 16.4323 16.4323 20 12 20C7.56771 20 4 16.4323 4 12C4 7.56771 7.56771 4 12 4ZM11 7V11H7V13H11V17H13V13H17V11H13V7H11Z"
        fill="currentColor"
      />
    </svg>
  )
}

PlusCircleIcon.displayName = 'PlusCircleIcon'
