import { useQuery, useQueryClient } from '@tanstack/react-query'
import { RouteContext } from '@tanstack/react-router'
import orderBy from 'lodash/orderBy'
import { useCallback, useMemo, useRef, useState } from 'react'
import { toast } from 'react-toastify'

import { TasksQueryTask } from '@/features/task/components/chat/ChatView'
import { useCreateChatMessage } from '@/features/task/components/chat/useCreateChatMessage'
import { useTask } from '@/features/task/hooks/useTask'
import { useTaskDetailPageContext } from '@/features/task/hooks/useTaskDetailPageContext'
import { CreateMessageInput } from '@/gql/generated/graphql'
import { useViewer } from '@/lib/hooks/useViewer'
import { logger } from '@/lib/logger'

const MESSAGES_REFETCH_INTERVAL = 1000

interface CreateFetchTaskMessagesOptions {
  api: RouteContext['api']
  taskId: string
}

export const createFetchTaskMessagesOptions = ({
  api,
  taskId,
}: CreateFetchTaskMessagesOptions) => {
  return {
    queryFn: async () => {
      const data = await api.fetchTaskMessages(taskId)
      return data.tasks[0]?.thread
    },
    queryKey: ['messages', taskId],
  }
}

type Thread = NonNullable<TasksQueryTask['thread']>

export const useChat = <T extends HTMLElement>(taskId: string) => {
  const { viewer } = useViewer()
  const { task } = useTask(taskId)
  const { api } = useTaskDetailPageContext()
  const queryKey = useMemo(() => ['messages', taskId], [taskId])
  const queryClient = useQueryClient()

  const newReceivedMessageReference = useRef<T>()
  const newMessageObserver = useRef<IntersectionObserver>()
  const previousMessageObserver = useRef<IntersectionObserver>()
  const previousMessagesCount = useRef<number>(0)

  const [isMessageInView, setIsMessageInView] = useState(false)
  const [hasNewReceivedMessage, setHasNewReceivedMessage] = useState(false)

  const handleScrollToNewMessage = useCallback(
    (options?: ScrollIntoViewOptions) => {
      newReceivedMessageReference.current?.scrollIntoView(
        options ?? { behavior: 'smooth' }
      )
    },
    []
  )

  const handleMutate = useCallback(
    async (variables: CreateMessageInput) => {
      await queryClient.cancelQueries({ queryKey })

      const previousThread = queryClient.getQueryData<Thread>(queryKey)

      if (!previousThread || !viewer) return

      queryClient.setQueryData(queryKey, {
        ...previousThread,
        messages: [
          ...(previousThread.messages ?? []),
          {
            author: viewer,
            body: variables.body,
            createdAt: new Date().toISOString(),
            id: `not-synced-${Date.now()}`,
            threadId: variables.threadId,
            type: 'MESSAGE',
          },
        ],
      })

      setIsMessageInView(true)
      handleScrollToNewMessage({ behavior: 'instant' })

      return previousThread
    },
    [queryClient, queryKey, viewer, handleScrollToNewMessage]
  )

  const handleError = useCallback(
    async (
      error: Error,
      _variables: CreateMessageInput,
      previousThread?: Thread | null
    ) => {
      if (previousThread) {
        queryClient.setQueryData(queryKey, previousThread)
      }

      logger.error(error)
      toast.error(error.message, {
        toastId: error.message,
      })
    },
    [queryClient, queryKey]
  )

  const { createNewMessage, isPending } = useCreateChatMessage({
    handleError,
    handleMutate,
  })

  const { data } = useQuery({
    ...createFetchTaskMessagesOptions({ api, taskId }),
    refetchInterval: MESSAGES_REFETCH_INTERVAL,
  })
  const messages = useMemo(
    () =>
      orderBy(
        data?.messages ?? task.thread?.messages,
        (message) => new Date(`${message.createdAt}`),
        'asc'
      ),
    [data?.messages, task.thread?.messages]
  )

  const previousMessageCallback = useCallback((node: T) => {
    // Scoll to bottom of chat on initialisation
    if (previousMessagesCount.current === 0) {
      node?.scrollIntoView({
        behavior: 'instant',
      })

      return
    }

    if (previousMessageObserver.current) {
      previousMessageObserver.current.disconnect()
    }

    previousMessageObserver.current = new IntersectionObserver((entries) => {
      // Scroll to newly received message if last message is in view
      if (entries[0]?.isIntersecting) {
        entries[0].target.nextElementSibling?.scrollIntoView({
          behavior: 'instant',
        })
        setIsMessageInView(true)
      }
    })

    if (node) {
      previousMessageObserver.current.observe(node)
    }
  }, [])

  const newMessageCallback = useCallback(
    (node: T) => {
      if (messages.length === 0) return

      if (newMessageObserver.current) {
        newMessageObserver.current.disconnect()
      }

      newMessageObserver.current = new IntersectionObserver((entries) => {
        // Determine when new message is received if new message is out of scroll
        if (entries[0]?.isIntersecting) return

        if (previousMessagesCount.current === 0) {
          previousMessagesCount.current = messages.length
          return
        }

        if (previousMessagesCount.current < messages.length) {
          setHasNewReceivedMessage(true)
          previousMessagesCount.current = messages.length
        } else {
          setIsMessageInView(false)
          setHasNewReceivedMessage(false)
        }
      })

      if (node) {
        newMessageObserver.current.observe(node)
        newReceivedMessageReference.current = node
      }
    },
    [messages]
  )

  return {
    createNewMessage,
    handleScrollToNewMessage,
    hasNewReceivedMessage: !isMessageInView && hasNewReceivedMessage,
    isCreatingNewMessage: isPending,
    messageThread: data,
    messages,
    newMessageCallback,
    previousMessageCallback,
  }
}
