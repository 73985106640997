import { useCallback, useMemo } from 'react'

import { CaretDownIcon } from '@/components/icons/CaretDownIcon'
import { CheckmarkDoneIcon } from '@/components/icons/CheckmarkDoneIcon'
import { CrossLargeIcon } from '@/components/icons/CrossLargeIcon'
import { Button } from '@/components/ui/button/Button'
import { Casing } from '@/components/ui/casing/Casing'
import { Form } from '@/components/ui/form/Form'
import { InputField } from '@/components/ui/input-field/InputField'
import * as PopoverPrimitive from '@/components/ui/popover/Popover'
import { TaskIcon } from '@/features/task/components/TaskIcon'
import { FetchTasksByIdQuery, UpdateTaskInput } from '@/gql/generated/graphql'
import { useBoolean } from '@/lib/hooks/useBoolean'

export interface TaskTitleProperties {
  onSubmit: (data: Partial<UpdateTaskInput>) => void
  task: FetchTasksByIdQuery['tasks'][number]
}

export const TaskTitle = ({ onSubmit, task }: TaskTitleProperties) => {
  const popoverVisibility = useBoolean(false)

  const handleSubmit = useCallback(
    (formData: Partial<UpdateTaskInput>) => {
      if (formData.title && formData.title !== task.title) {
        onSubmit({ ...formData, id: task.id })
      }
      popoverVisibility.setFalse()
    },
    [onSubmit, popoverVisibility, task.id, task.title]
  )

  const defaultValues = useMemo(
    () => ({
      title: task.title,
    }),
    [task.title]
  )

  return (
    <div className={'flex flex-row py-2'}>
      <Casing className="grow items-center" size="large" variant="naked">
        <PopoverPrimitive.Popover
          onOpenChange={popoverVisibility.toggle}
          open={popoverVisibility.value}
        >
          <PopoverPrimitive.PopoverTrigger
            className="flex items-center gap-2 rounded-sm border border-transparent hover:border-film-subtle hover:bg-film-subtle focus-visible:border-film-subtle focus-visible:bg-film-subtle active:bg-film-stronger"
            data-testid="update-task-title-popover-trigger"
          >
            <TaskIcon status={task.status} />
            <h1
              className="block grow text-left text-md-bold"
              data-testid="task-title"
            >
              {task.title}
            </h1>
            <CaretDownIcon className="min-w-4" />
          </PopoverPrimitive.PopoverTrigger>
          <PopoverPrimitive.PopoverPortal>
            <PopoverPrimitive.PopoverContent className="z-50">
              <p className="mb-1 border-b border-film-subtle px-2 pb-1 text-xs-bold text-mono-ink-subtle">
                Rename task
              </p>

              <Form
                defaultValues={defaultValues}
                onSubmit={handleSubmit}
                onCmdEnterKey={handleSubmit}
              >
                <InputField
                  className="h-8 w-full rounded-md border border-transparent p-2 text-sm-regular outline-none focus-visible:border-blue-ink-subtle"
                  data-testid="update-task-title-input"
                  name="title"
                />

                <div className="my-1 flex justify-end gap-1">
                  <Button
                    className="h-7 min-h-7 w-9 min-w-9"
                    data-testid="update-task-title-popover-cancel-button"
                    onClick={popoverVisibility.setFalse}
                  >
                    <CrossLargeIcon className="size-5 min-h-5 min-w-5" />
                  </Button>

                  <Button
                    className="h-7 min-h-7 w-9 min-w-9"
                    data-testid="update-task-title-popover-submit-button"
                    type="submit"
                    variant="solid"
                  >
                    <CheckmarkDoneIcon className="size-5 min-h-5 min-w-5" />
                  </Button>
                </div>
              </Form>
            </PopoverPrimitive.PopoverContent>
          </PopoverPrimitive.PopoverPortal>
        </PopoverPrimitive.Popover>
      </Casing>
    </div>
  )
}

TaskTitle.displayName = 'TaskTitle'
