import { IconComponent } from '@/lib/types'

export const MagicIcon: IconComponent = ({ ...properties }) => {
  return (
    <svg
      width="25"
      height="24"
      viewBox="0 0 25 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...properties}
    >
      <path
        d="M16 20.5C15.6745 20.5 15.3854 20.2891 15.2865 19.9792L13.8229 15.4271L9.27083 13.9635C8.96094 13.8646 8.75 13.5755 8.75 13.25C8.75 12.9245 8.96094 12.6354 9.27083 12.5365L13.8229 11.0729L15.2865 6.52083C15.3854 6.21094 15.6745 6 16 6C16.3255 6 16.6146 6.21094 16.7135 6.52083L18.1771 11.0729L22.7292 12.5365C23.0391 12.6354 23.25 12.9245 23.25 13.25C23.25 13.5755 23.0391 13.8646 22.7292 13.9635L18.1771 15.4271L16.7135 19.9792C16.6146 20.2891 16.3255 20.5 16 20.5ZM6.25 11.5C5.92708 11.5 5.64063 11.2943 5.53906 10.987L4.59375 8.15625L1.76302 7.21094C1.45573 7.10938 1.25 6.82292 1.25 6.5C1.25 6.17708 1.45573 5.89063 1.76302 5.78906L4.59375 4.84375L5.53906 2.01302C5.64063 1.70573 5.92708 1.5 6.25 1.5C6.57292 1.5 6.85938 1.70573 6.96094 2.01302L7.90625 4.84375L10.737 5.78906C11.0443 5.89063 11.25 6.17708 11.25 6.5C11.25 6.82292 11.0443 7.10938 10.737 7.21094L7.90625 8.15625L6.96094 10.987C6.85938 11.2943 6.57292 11.5 6.25 11.5ZM5.5 22.5C5.17708 22.5 4.89063 22.2943 4.78906 21.987L4.03125 19.7188L1.76302 18.9609C1.45573 18.8594 1.25 18.5729 1.25 18.25C1.25 17.9271 1.45573 17.6406 1.76302 17.5391L4.03125 16.7812L4.78906 14.513C4.89063 14.2057 5.17708 14 5.5 14C5.82292 14 6.10938 14.2057 6.21094 14.513L6.96875 16.7812L9.23698 17.5391C9.54427 17.6406 9.75 17.9271 9.75 18.25C9.75 18.5729 9.54427 18.8594 9.23698 18.9609L6.96875 19.7188L6.21094 21.987C6.10938 22.2943 5.82292 22.5 5.5 22.5Z"
        fill="currentColor"
      />
    </svg>
  )
}

MagicIcon.displayName = 'MagicIcon'
