import { useCallback } from 'react'

import { useOnKeyDown } from '@/lib/hooks/useOnKeyDown'
import { isCtrlPressed } from '@/lib/utils'

export const useOnMetaEnter = <T extends HTMLElement>(callback: () => void) => {
  const onKeyDown = useOnKeyDown<T>(
    'Enter',
    useCallback(
      (event: React.KeyboardEvent<T>) => {
        if (isCtrlPressed(event)) {
          callback()
        }
      },
      [callback]
    )
  )

  return onKeyDown
}
