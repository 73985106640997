import { IconComponent } from '@/lib/types'

export const CheckedCircleSolidIcon: IconComponent = ({ ...properties }) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...properties}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2 12C2 6.48698 6.48698 2 12 2C17.513 2 22 6.48698 22 12C22 17.513 17.513 22 12 22C6.48698 22 2 17.513 2 12ZM17.1622 9.33007C17.4812 8.94895 17.4309 8.38132 17.0498 8.06225C16.6687 7.74318 16.101 7.79349 15.782 8.17462L10.5742 14.3953L8.18124 11.8697C7.83937 11.5089 7.26973 11.4936 6.90891 11.8354C6.5481 12.1773 6.53275 12.7469 6.87462 13.1078L9.96247 16.3667C10.1391 16.553 10.3868 16.6551 10.6434 16.6472C10.9 16.6393 11.1411 16.5222 11.3059 16.3254L17.1622 9.33007Z"
        fill="currentColor"
      />
    </svg>
  )
}

CheckedCircleSolidIcon.displayName = 'CheckedCircleSolidIcon'
