import { Root, Thumb, SwitchProps } from '@radix-ui/react-switch'

import { cn } from '@/lib/utils'

export const Switch = ({ className, ...rest }: SwitchProps) => {
  return (
    <Root
      {...rest}
      className={cn(
        className,
        `relative h-4.5 w-8 rounded-full border-[1.5px] border-slate-400 bg-slate-400 transition-all`,
        `[&[data-state="checked"]]:border-sky-600 [&[data-state="checked"]]:bg-sky-500`
      )}
    >
      <Thumb
        className={cn(
          `absolute left-0 top-0 h-[15px] w-[15px] rounded-full border-[1.5px] border-slate-400 bg-film-normal-non-transparent transition-all`,
          `[&[data-state="checked"]]:translate-x-[calc(100%-1px)] [&[data-state="checked"]]:border-sky-600`
        )}
      />
    </Root>
  )
}

Switch.displayName = 'Switch'
