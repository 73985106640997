import * as React from 'react'

import { Button } from '@/components/ui/button/Button'
import { Form } from '@/components/ui/form/Form'
import { TextArea } from '@/components/ui/text-area/TextArea'
import { UpdateTaskInput } from '@/gql/generated/graphql'
import { useBoolean } from '@/lib/hooks/useBoolean'
import { cn } from '@/lib/utils'

interface TaskDescriptionProperties {
  onSave: (formData: Partial<UpdateTaskInput>) => void
  taskDescription?: string | null
}

export const TaskDescription = ({
  onSave,
  taskDescription,
}: TaskDescriptionProperties) => {
  const { setFalse, setTrue, value } = useBoolean()
  const textAreaReference = React.useRef<HTMLTextAreaElement>(null)
  const [description, setDescription] = React.useState(taskDescription?.trim())
  const previousDescription = React.useRef<string | null | undefined>(
    taskDescription ?? undefined
  )

  const handleSave = React.useCallback(() => {
    if (previousDescription.current !== description) {
      onSave({ description })
      setFalse()
      previousDescription.current = description
      textAreaReference.current?.blur()
    }
  }, [description, onSave, setFalse])

  const handleSubmit = React.useCallback(() => {
    if (previousDescription.current !== description) {
      handleSave()
    }
    setFalse()
  }, [description, handleSave, setFalse])

  const handleBlur = React.useCallback(() => {
    if (previousDescription.current !== description) {
      handleSave()
      return
    }

    setFalse()
  }, [description, handleSave, setFalse])

  const handleChange = React.useCallback(
    (event: React.ChangeEvent<HTMLTextAreaElement>) => {
      setDescription(event.target.value)
    },
    []
  )

  const handleReset = React.useCallback(() => {
    setDescription(previousDescription.current ?? '')
    setFalse()
    textAreaReference.current?.blur()
  }, [setFalse])

  const handleFocus = React.useCallback(
    (event: React.FocusEvent<HTMLFormElement>) => {
      if (event.target instanceof HTMLAnchorElement) return

      setTrue()
    },
    [setTrue]
  )

  return (
    <Form
      onSubmit={handleSubmit}
      onCmdEnterKey={handleSubmit}
      onClickAway={handleBlur}
      onEscapeKey={handleReset}
      onFocus={handleFocus}
      onReset={handleReset}
      onBlur={handleBlur}
    >
      <TextArea
        mode="controlled"
        data-testid="task-description-textarea"
        name="description"
        placeholder="Add task details..."
        onChange={handleChange}
        value={description ?? ''}
        ref={textAreaReference}
        focused={value}
      />
      <div className={cn('flex justify-end gap-1 pt-2', { hidden: !value })}>
        <Button
          size="sm"
          type="reset"
          variant="naked"
          data-testid="button-discard-description"
          aria-hidden={!value}
        >
          Discard
        </Button>
        <Button
          size="sm"
          type="submit"
          variant="solid"
          data-testid="button-save-description"
          aria-hidden={!value}
        >
          Save
        </Button>
      </div>
    </Form>
  )
}

TaskDescription.displayName = 'TaskDescription'
