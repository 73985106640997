import { useNavigate } from '@tanstack/react-router'
import { motion } from 'framer-motion'
import { Dispatch, useCallback } from 'react'

import { SubtaskWithPopover } from '@/features/task/components/subtasks/SubtaskWithPopover'
import {
  SubtaskListAction,
  subtaskListSelectorsFactory,
  SubtaskListState,
} from '@/features/task/components/subtasks/reducer'
import { TaskData } from '@/features/task/hooks/useTask'
import { useUpdateTaskStatus } from '@/features/task/hooks/useUpdateTaskStatus'
import { staggerMotionVariants } from '@/features/task/utils/constants'
import { TaskStatus } from '@/gql/generated/graphql'
import { cn } from '@/lib/utils'

type SubtaskData = TaskData['subtasks'][number]

interface SubtasksListItemProperties {
  task: SubtaskData
  index: number
  subtasksLength: number
  onDelete: (ids: string[]) => void
  onMove: (id: string) => void
  onUpdateSubtaskTitle: (title: string, id: string) => void
  handleArrowUpDown: (event: KeyboardEvent) => boolean
  state: SubtaskListState
  dispatch: Dispatch<SubtaskListAction>
  parentId: string
}

export const SubtasksListItem = ({
  dispatch,
  index,
  onDelete,
  onMove,
  onUpdateSubtaskTitle,
  parentId,
  state,
  subtasksLength,
  task,
  ...rest
}: SubtasksListItemProperties) => {
  const navigate = useNavigate()
  const selectors = subtaskListSelectorsFactory(state)
  const { updateTaskStatus } = useUpdateTaskStatus(task.id)
  const isHighlighted = selectors.isHighlighted(task.id)

  const isNextSelected = selectors.isNextHighlighted(task.id)

  const handleToggleComplete = useCallback(() => {
    updateTaskStatus({
      id: task.id,
      status: task.completedAt ? 'OPEN' : 'COMPLETED',
    })
    dispatch({ type: 'onContextMenuClose' })
  }, [updateTaskStatus, task.id, task.completedAt, dispatch])

  const handleTaskStatusChange = useCallback(
    (status: TaskStatus) => {
      updateTaskStatus({
        id: task.id,
        status,
      })
      dispatch({ type: 'onContextMenuClose' })
    },
    [updateTaskStatus, task.id, dispatch]
  )

  const handleUpdateSubtaskTitle = useCallback(
    (title: string) => {
      onUpdateSubtaskTitle(title, task.id)
    },
    [onUpdateSubtaskTitle, task.id]
  )

  const navigateToSubtask = useCallback(() => {
    void navigate({
      params: { taskId: task.id },
      to: '/all-work/$taskId',
    })
  }, [navigate, task.id])

  return (
    <motion.li
      key={task.id}
      custom={[index, index + 1 === subtasksLength]}
      variants={staggerMotionVariants}
      initial="hidden"
      animate="visible"
      className={cn(
        'border-b border-film-subtle bg-mono-paper last:border-none [&:has([data-popover=true])]:mb-2 [&:has([data-popover=true])]:overflow-hidden [&:has([data-popover=true])]:rounded-lg',
        {
          'border-sky-300': isHighlighted && isNextSelected,
        },
        {
          '[&:has([data-popover=true])]:mt-2': index > 0,
        },
        {
          '[&:has([data-popover=false])]:mb-0': index === subtasksLength - 1,
        }
      )}
    >
      <SubtaskWithPopover
        onDoubleClick={navigateToSubtask}
        state={state}
        {...rest}
        onDelete={onDelete}
        onMove={onMove}
        onRename={handleUpdateSubtaskTitle}
        tabIndex={0}
        task={task}
        onToggleComplete={handleToggleComplete}
        onTaskStatusChange={handleTaskStatusChange}
        dispatch={dispatch}
        parentId={parentId}
        aria-selected={isHighlighted}
        data-testid={`subtask-listitem-${task.id}`}
      />
    </motion.li>
  )
}

SubtasksListItem.displayName = 'SubtasksListItem'
