import * as PopoverPrimitive from '@radix-ui/react-popover'
import * as React from 'react'

import { CheckedCircleSolidIcon } from '@/components/icons/CheckedCircleSolidIcon'
import { CircleCrossFilledIcon } from '@/components/icons/CircleCrossFilledIcon'
import { CopyLinkToClipboardIcon } from '@/components/icons/CopyLinkToClipboardIcon'
import { PasteClipboardIcon } from '@/components/icons/PasteClipboardIcon'
import { TaskCircularCheckboxEmptyIcon } from '@/components/icons/TaskCircularCheckboxEmptyIcon'
import { Button } from '@/components/ui/button/Button'
import { ArrowRightIcon } from '@/features/task/components/subtasks/ArrowRightIcon'
import { RenameIcon } from '@/features/task/components/subtasks/RenameIcon'
import { TrashIcon } from '@/features/task/components/subtasks/TrashIcon'
import { TaskStatus } from '@/gql/generated/graphql'
import { useClickOutside } from '@/lib/hooks/useClickOutside'
import { useDocumentKeyCapture } from '@/lib/hooks/useDocumentKeyCapture'
import { cn } from '@/lib/utils'

interface SubtaskPopoverContentProperties {
  taskId: string
  title: string
  onCopyToClipboard: () => void
  onDelete: () => void
  onRename: () => void
  onTaskStatusChange: (status: TaskStatus) => void
  onOpenTask: () => void
  onPasteTask?: () => void
  isCompleted: boolean
  onClose: () => void
}

export const SubtaskPopoverContent = React.forwardRef<
  HTMLDivElement,
  SubtaskPopoverContentProperties
>(
  (
    {
      isCompleted,
      onClose,
      onCopyToClipboard,
      onDelete,
      onOpenTask,
      onPasteTask,
      onRename,
      onTaskStatusChange,
      taskId,
      title,
      ...rest
    },
    reference
  ) => {
    const [activeElementIndex, setActiveElementIndex] = React.useState(-1)

    const popoverElements = React.useMemo(
      () => [
        {
          content: (
            <>
              <TaskCircularCheckboxEmptyIcon className="size-6" />
              Mark open
            </>
          ),
          id: 'subtask-context-mark-open',
          key: 'mark-open',
          onClick: () => {
            onTaskStatusChange('OPEN')
          },
        },
        {
          content: (
            <>
              <CheckedCircleSolidIcon className="size-6" />
              Mark complete
            </>
          ),
          id: 'subtask-context-mark-complete',
          key: 'mark-complete',
          onClick: () => {
            onTaskStatusChange('COMPLETED')
          },
        },
        {
          content: (
            <>
              <CircleCrossFilledIcon className="size-6" />
              Mark cancelled
            </>
          ),
          id: 'subtask-context-mark-cancelled',
          key: 'mark-cancelled',
          onClick: () => {
            onTaskStatusChange('CANCELLED')
          },
        },
        {
          content: (
            <>
              <ArrowRightIcon className="size-6" />
              Open
              <span className="sr-only">{title} task</span>
            </>
          ),
          id: 'subtask-context-open',
          key: 'open',
          onClick: onOpenTask,
        },
        {
          content: (
            <>
              <TrashIcon className="size-6" />
              Delete
            </>
          ),
          id: 'subtask-context-delete',
          key: 'delete',
          onClick: onDelete,
        },
        {
          content: (
            <>
              <RenameIcon className="size-6" />
              Rename
            </>
          ),
          id: 'subtask-context-rename',
          key: 'rename',
          onClick: onRename,
        },
        {
          content: (
            <>
              <CopyLinkToClipboardIcon className="size-6" />
              Copy link
            </>
          ),
          id: 'subtask-context-clipboard-copy',
          key: 'copy',
          onClick: onCopyToClipboard,
        },
        {
          content: (
            <>
              <PasteClipboardIcon className="size-6" />
              Paste
            </>
          ),
          id: 'subtask-context-clipboard-paste',
          key: 'paste',
          onClick: onPasteTask,
        },
      ],
      [
        title,
        onOpenTask,
        onDelete,
        onRename,
        onCopyToClipboard,
        onPasteTask,
        onTaskStatusChange,
      ]
    )

    const outsideClickReference = useClickOutside<HTMLDivElement>(onClose)

    const increaseActivePopoverElement = React.useCallback(
      ({ event }: { event: KeyboardEvent }) => {
        event.preventDefault()
        setActiveElementIndex(
          (previous) => (previous + 1) % popoverElements.length
        )
      },
      [popoverElements.length]
    )

    const decreaseActivePopoverElement = React.useCallback(
      ({ event }: { event: KeyboardEvent }) => {
        event.preventDefault()
        setActiveElementIndex(
          (previous) =>
            (previous - 1 + popoverElements.length) % popoverElements.length
        )
      },
      [popoverElements.length]
    )

    useDocumentKeyCapture('ArrowDown', increaseActivePopoverElement)
    useDocumentKeyCapture('ArrowUp', decreaseActivePopoverElement)
    useDocumentKeyCapture(
      'Enter',
      React.useCallback(
        ({ event }) => {
          event.preventDefault()
          popoverElements[activeElementIndex]?.onClick?.()
        },
        [activeElementIndex, popoverElements]
      )
    )
    useDocumentKeyCapture(
      'Tab',
      React.useCallback(({ event }: { event: KeyboardEvent }) => {
        event.preventDefault()
      }, [])
    )
    useDocumentKeyCapture('Escape', onClose)

    return (
      <PopoverPrimitive.PopoverContent
        {...rest}
        ref={reference}
        align="start"
        alignOffset={0}
        side="bottom"
        sideOffset={13}
        avoidCollisions={true}
        onOpenAutoFocus={(event) => {
          event.preventDefault()
        }}
        data-testid={`subtask-context-menu-${taskId}`}
        className="z-50"
      >
        <div
          className="w-[251px] rounded-lg bg-mono-paper p-1 shadow-just-shadow"
          ref={outsideClickReference}
        >
          {popoverElements.map((element, index) => {
            return (
              <Button
                size="sm"
                variant="naked"
                className={cn('w-full justify-start hover:shadow-none', {
                  'bg-sky-600 text-mono-paper shadow-none':
                    index === activeElementIndex,
                })}
                aria-selected={index === activeElementIndex}
                onClick={element.onClick}
                key={element.key}
                tabIndex={-1}
                data-testid={`${element.id}-${taskId}`}
              >
                {element.content}
              </Button>
            )
          })}
        </div>
      </PopoverPrimitive.PopoverContent>
    )
  }
)

SubtaskPopoverContent.displayName = 'SubtaskPopoverContent'
