import { useMemo } from 'react'

import { HelixLoader } from '@/features/task/components/helix-loader/HelixLoader'
import { SubtasksView } from '@/features/task/components/subtasks/SubtasksView'

export const SubtasksPlanningLoadingView = () => {
  return (
    <SubtasksView
      containerClassName="border-indigo-300 bg-gradient-indigo"
      heading={useMemo(
        () => (
          <>
            <div className="flex items-center gap-1 text-xs font-medium uppercase text-indigo-600">
              <span className="px-2">Plan</span>
            </div>

            <span className="block h-8" />
          </>
        ),
        []
      )}
      body={useMemo(
        () => (
          <div className="flex flex-col gap-3">
            <div className="flex h-[236px] w-full justify-center">
              <div className="flex flex-col items-center">
                <HelixLoader className="mt-2" />
                <div className="text-sm-thin -mt-2 text-indigo-400">
                  Creating plan suggestion
                </div>
              </div>
            </div>
          </div>
        ),
        []
      )}
    />
  )
}

SubtasksPlanningLoadingView.displayName = 'SubtasksPlanningLoadingView'
