import pluralize from 'pluralize'
import { PropsWithChildren, useCallback } from 'react'

import { AlertDialog } from '@/components/ui/alertDialog/AlertDialog'
import { SubtaskDialogItem } from '@/features/task/components/subtasks/SubtaskDialogItem'
import { FetchTasksByIdQuery } from '@/gql/generated/graphql'

interface DeleteTaskDialogProperties {
  onDeleteClick: (ids: string[]) => void
  onOpenChange?: (open: boolean) => void
  isDialogOpen?: boolean
  itemsToDelete: Array<{
    id: string
    assignee?: FetchTasksByIdQuery['tasks'][number]['subtasks'][number]['assignee']
    title?: string
    completedAt?: string | null
  }>
}

const getReadableTaskId = (id: string) => {
  return `TASK-${id.slice(-4)}`
}

export const DeleteTasksDialog = ({
  children,
  isDialogOpen,
  itemsToDelete,
  onDeleteClick,
  onOpenChange,
}: PropsWithChildren<DeleteTaskDialogProperties>) => {
  const onActionClick = useCallback(() => {
    onDeleteClick(itemsToDelete.map((item) => item.id))
  }, [onDeleteClick, itemsToDelete])

  return (
    <AlertDialog
      actionText="Delete"
      cancelText="Cancel"
      description="All subordinate tasks will also be deleted. Deleted tasks can not be recovered."
      onActionClick={onActionClick}
      onOpenChange={onOpenChange}
      title={`Are you sure you want to delete ${
        itemsToDelete.length > 1
          ? `${itemsToDelete.length} tasks`
          : `the ${getReadableTaskId(itemsToDelete[0]?.id ?? '')}`
      }?`}
      isDialogOpen={isDialogOpen}
      beforeTitle={
        itemsToDelete.length > 1 ? (
          <div className="pb-1">
            <p className="mb-2 font-medium text-mono-ink-subtle">
              {itemsToDelete.length} tasks selected
            </p>
            {itemsToDelete.slice(0, 3).map((item) => (
              <SubtaskDialogItem
                key={item.id}
                taskId={item.id}
                assignee={item.assignee}
                title={item.title ?? ''}
                isCompleted={!!item.completedAt}
              />
            ))}
            {itemsToDelete.length > 3 && (
              <p className="mb-2 flex h-8 items-center justify-center rounded-lg border border-film-strong px-9 text-center text-xs font-semibold leading-4 text-mono-ink-subtle">
                {itemsToDelete.length - 3} more{' '}
                {pluralize('task', itemsToDelete.length - 3)}
              </p>
            )}
          </div>
        ) : (
          getReadableTaskId(itemsToDelete[0]?.id ?? '')
        )
      }
    >
      {children}
    </AlertDialog>
  )
}

DeleteTasksDialog.displayName = 'DeleteTaskDialog'
