import { useCallback, useEffect, useRef } from 'react'

export const useDocumentKeyCapture = (
  keys: string | string[],
  callback: (parameters: { event: KeyboardEvent }) => void,
  isActive = true,
  phase: 'capture' | 'bubble' = 'bubble'
) => {
  const pressedKeys = useRef<Set<string>>(new Set())
  const isCorrectOrder = useRef<boolean>(false)

  const handleKeyDown = useCallback(
    (event: KeyboardEvent) => {
      // Normalize keys to always be an array
      const keyArray = Array.isArray(keys) ? keys : [keys]

      // Check if the pressed key is part of the desired combination
      if (keyArray.includes(event.key)) {
        pressedKeys.current.add(event.key)

        // Validate the order of pressed keys
        if (pressedKeys.current.size === keyArray.length) {
          isCorrectOrder.current = [...pressedKeys.current].every(
            (key, index) => keyArray[index] === key
          )
        }

        // Invoke callback if keys match the desired combination in correct order
        if (
          isCorrectOrder.current &&
          pressedKeys.current.size === keyArray.length
        ) {
          callback({ event })
          pressedKeys.current.clear()
          isCorrectOrder.current = false
        }
      }
    },
    [keys, callback]
  )

  const handleKeyUp = useCallback((event: KeyboardEvent) => {
    pressedKeys.current.delete(event.key)
  }, [])

  useEffect(() => {
    if (!isActive) {
      return
    }

    document.addEventListener('keydown', handleKeyDown, {
      capture: phase === 'capture',
    })
    document.addEventListener('keyup', handleKeyUp, {
      capture: phase === 'capture',
    })

    return () => {
      document.removeEventListener('keydown', handleKeyDown, {
        capture: phase === 'capture',
      })
      document.removeEventListener('keyup', handleKeyUp, {
        capture: phase === 'capture',
      })
    }
  }, [handleKeyDown, handleKeyUp, isActive, phase])
}
