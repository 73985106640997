import { useCallback } from 'react'

import { ArrowDownIcon } from '@/components/icons/ArrowDownIcon'
import { CheckedCircleSolidIcon } from '@/components/icons/CheckedCircleSolidIcon'
import { CircleCrossFilledIcon } from '@/components/icons/CircleCrossFilledIcon'
import { PlusMathIcon } from '@/components/icons/PlusMathIcon'
import { Avatar } from '@/components/ui/avatar/Avatar'
import { Button } from '@/components/ui/button/Button'
import { Tooltip } from '@/components/ui/tooltip/Tooltip'
import { UnassignedIcon } from '@/features/task/components/UnassignedIcon'
import { ChatMessage } from '@/features/task/components/chat/ChatMessage'
import { ChatMessageInput } from '@/features/task/components/chat/ChatMessageInput'
import { CircleIcon } from '@/features/task/components/chat/CircleIcon'
import { useChat } from '@/features/task/components/chat/useChat'
import { useUsers } from '@/features/task/hooks/useUsers'
import { FetchTasksByIdQuery, MessageType } from '@/gql/generated/graphql'
import { useViewer } from '@/lib/hooks/useViewer'
import { cn, formatMessageTimestamp } from '@/lib/utils'

const messageTypeIcons: Record<MessageType, React.ReactNode> = {
  MESSAGE: undefined,
  TASK_CREATED: <PlusMathIcon className="h-4 w-4 text-white" />,
  TASK_STATUS_CANCELLED: <CircleCrossFilledIcon className="text-white" />,
  TASK_STATUS_COMPLETED: <CheckedCircleSolidIcon className="text-white" />,
  TASK_STATUS_OPENED: <CircleIcon />,
}

export type TasksQueryTask = FetchTasksByIdQuery['tasks'][number]

export const ChatView = ({ taskId }: { taskId: string }) => {
  const { viewer } = useViewer()
  const { users } = useUsers()
  const {
    createNewMessage,
    handleScrollToNewMessage,
    hasNewReceivedMessage,
    isCreatingNewMessage,
    messageThread,
    messages,
    newMessageCallback,
    previousMessageCallback,
  } = useChat<HTMLDivElement>(taskId)

  const handleSendMessage = useCallback(
    (data: { message: string }) => {
      void (async () => {
        if (messageThread) {
          await createNewMessage({
            body: {
              text: data.message,
            },
            threadId: messageThread.id,
          })
        }
      })()
    },
    [createNewMessage, messageThread]
  )

  const handleScrollToNewMessageSync = useCallback(() => {
    handleScrollToNewMessage()
  }, [handleScrollToNewMessage])

  return (
    <div className="flex flex-1 flex-col justify-end overflow-hidden">
      <div className="flex flex-col gap-6 overflow-y-auto pb-6">
        {messages.map((message, index) => {
          const isNewMessage = messages.length === index + 1
          const isPreviousMessage = messages.length - 2 === index
          const localizedDate = new Date(message.createdAt).toLocaleString()

          return (
            <div
              key={message.id}
              data-testid={`message-${message.id}`}
              className="flex gap-3 bg-none"
              ref={
                isNewMessage
                  ? newMessageCallback
                  : isPreviousMessage
                    ? previousMessageCallback
                    : undefined
              }
            >
              <div>
                {viewer ? (
                  <Avatar
                    alt={message.author?.name ?? viewer.name}
                    src={message.author?.avatarUrl ?? viewer.avatarUrl}
                    className="size-6 min-h-6 min-w-6 rounded-full"
                  />
                ) : (
                  <UnassignedIcon className="size-6 min-h-6 min-w-6" />
                )}
              </div>

              <div
                className={cn('flex flex-col items-start gap-2', {
                  truncate: message.type !== 'MESSAGE',
                })}
              >
                <div className="flex items-center gap-2">
                  <p className="text-md-bold text-mono-ink-strong">
                    {message.author?.name ?? viewer?.name ?? 'Odin'}
                  </p>
                  {message.type !== 'MESSAGE' && (
                    <>
                      <p className="text-sm-regular text-mono-ink-subtle">
                        {message.body.text}
                      </p>
                      <div className="h-0.75 w-0.75 rounded-full bg-film-strongest" />
                    </>
                  )}

                  <Tooltip
                    content={localizedDate}
                    side="top"
                    data-testid={`message-timestamp-tooltip-${message.id}`}
                  >
                    <span
                      className="text-xs-regular text-film-strongest"
                      data-testid={`message-timestamp-${message.id}`}
                    >
                      {formatMessageTimestamp(message.createdAt)}
                    </span>
                  </Tooltip>
                </div>

                {message.type === 'MESSAGE' ? (
                  <p
                    className="min-w-1 whitespace-pre-wrap rounded-lg border bg-film-subtle p-3"
                    data-testid={`message-text-${message.id}`}
                  >
                    <ChatMessage message={message.body.text} users={users} />
                  </p>
                ) : (
                  <div className="flex w-fit max-w-full items-center gap-1 truncate rounded-[66px] border border-film-faint bg-[#ECE8E1] p-1.5">
                    <div className="flex h-8 w-8 shrink-0 items-center justify-center rounded-full bg-gold p-1">
                      {messageTypeIcons[message.type]}
                    </div>
                    <div className="flex w-full flex-col truncate">
                      <p className="w-full truncate text-sm font-medium text-[#694C2F]">
                        {message.task?.title}
                      </p>
                      {message.task?.description && (
                        <p className="w-full truncate text-xs-regular text-mono-ink-subtle">
                          {message.task?.description}
                        </p>
                      )}
                    </div>
                  </div>
                )}
              </div>
            </div>
          )
        })}
      </div>

      <div className="relative">
        {hasNewReceivedMessage && (
          <Button
            size="sm"
            variant="solid"
            type="button"
            className="absolute bottom-[100%] left-[50%] mb-6 translate-x-[-50%]"
            onClick={handleScrollToNewMessageSync}
          >
            <ArrowDownIcon />
            New messages
          </Button>
        )}

        <ChatMessageInput
          onSendMessage={handleSendMessage}
          disabled={isCreatingNewMessage}
        />
      </div>
    </div>
  )
}

ChatView.displayName = 'ChatView'
