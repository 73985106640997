import { IconComponent } from '@/lib/types'

export const ExpandArrowsIcon: IconComponent = ({ ...properties }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      fill="none"
      viewBox="0 0 20 20"
      {...properties}
    >
      <g>
        <g fill="currentColor">
          <path d="M8.197 11.406a.832.832 0 00-.56.248L4.14 15.152v-2.105a.812.812 0 00-.825-.829.818.818 0 00-.803.829v3.95c-.043.26.04.525.226.712.187.186.451.269.712.225h3.95a.815.815 0 100-1.628H5.292l3.499-3.498a.822.822 0 00.182-.902.82.82 0 00-.777-.5zM17.017 2.509a.825.825 0 01.72.26c.16.183.234.421.2.656v3.971a.815.815 0 11-1.628 0V5.291l-3.498 3.498a.806.806 0 01-.794.222.806.806 0 01-.582-.582.806.806 0 01.221-.794l3.499-3.499H13.05a.815.815 0 110-1.628h3.967z"></path>
        </g>
      </g>
    </svg>
  )
}

ExpandArrowsIcon.displayName = 'ExpandArrowsIcon'
