import { useDocumentKeyCapture } from '@/lib/hooks/useDocumentKeyCapture'
import { isCtrlPressed, isMac } from '@/lib/utils'

const macNavigateToTaskKeys = ['Meta', 'Enter']
const otherNavigateToTaskKeys = ['Control', 'Enter']

export const useTaskItemKeyNavigation = (
  navigateToTask: () => void,
  handleOpenStack: () => void,
  openTaskConditions: boolean
) => {
  useDocumentKeyCapture(
    'Enter',
    ({ event }) => {
      if (!isCtrlPressed(event)) {
        handleOpenStack()
      }
    },
    openTaskConditions
  )
  useDocumentKeyCapture(
    isMac() ? macNavigateToTaskKeys : otherNavigateToTaskKeys,
    navigateToTask,
    openTaskConditions
  )
}
