import { forwardRef, useCallback, useMemo } from 'react'

import { SmartSuspense } from '@/components/SmartSuspense'
import { Skeleton } from '@/components/ui/skeleton/Skeleton'
import { TaskAssignee } from '@/components/ui/task-assignee/TaskAssignee'
import { DeleteTaskPopover } from '@/features/task/components/DeleteTaskPopover'
import { TaskDescription } from '@/features/task/components/TaskDescription'
import { TaskDueAtSelect } from '@/features/task/components/TaskDueAtSelect'
import { TaskStatusSelect } from '@/features/task/components/TaskStatusSelect'
import { ChatView } from '@/features/task/components/chat/ChatView'
import { Subtasks } from '@/features/task/components/subtasks/Subtasks'
import { useCreateTask } from '@/features/task/hooks/useCreateTask'
import { useTask } from '@/features/task/hooks/useTask'
import { useUpdateTask } from '@/features/task/hooks/useUpdateTask'
import { useUpdateTaskStatus } from '@/features/task/hooks/useUpdateTaskStatus'
import { useUsers } from '@/features/task/hooks/useUsers'
import { TaskStatus, UpdateTaskInput } from '@/gql/generated/graphql'
import { cn } from '@/lib/utils'

interface TaskThreadProperties {
  className?: string
  isChatOverlayVisible: boolean
  id: string
  isRenderedInStackCard: boolean
}

const TaskThread = forwardRef<HTMLDivElement, TaskThreadProperties>(
  (
    {
      className,
      id,
      isChatOverlayVisible,
      isRenderedInStackCard,
      ...properties
    },
    reference
  ) => {
    const { task } = useTask(id)
    const { users } = useUsers()
    const { isUpdatingTask, updateTask } = useUpdateTask(id)
    const { isUpdatingTaskStatus, updateTaskStatus } = useUpdateTaskStatus(id)
    const { createTask, isCreatingTask } = useCreateTask({
      taskId: id,
    })
    const isMutationPending =
      isUpdatingTask || isCreatingTask || isUpdatingTaskStatus
    const handleUpdateTask = useCallback(
      (data: Partial<UpdateTaskInput>) => {
        if (!task.id) return
        updateTask({
          ...data,
          id: task.id,
        })
      },
      [task.id, updateTask]
    )

    const handleStatusChange = useCallback(
      (status: TaskStatus) => {
        if (!task.id) return
        updateTaskStatus({ id: task.id, status })
      },
      [task.id, updateTaskStatus]
    )

    const handleUpdateSubtaskTitle = useCallback(
      (title: string, id: string) => {
        updateTask({
          id,
          title,
        })
      },
      [updateTask]
    )

    return (
      <div
        ref={reference}
        className={cn(
          'flex h-full flex-1 grow flex-col overflow-auto @2xl/task:flex-row @2xl/task:overflow-hidden [&[data-state="inactive"]]:hidden',
          className
        )}
        {...properties}
      >
        <div className="w-full max-w-[44rem] border-film-subtle px-5 pb-5 @2xl/task:w-1/2 @2xl/task:flex-1 @2xl/task:overflow-y-auto @2xl/task:border-r">
          <div className="flex gap-2 py-4">
            <TaskAssignee
              className="flex-1"
              assigneeId={task.assignee?.id}
              disabled={isMutationPending}
              onSelect={handleUpdateTask}
              users={users}
            />
            <TaskStatusSelect
              onSelect={handleStatusChange}
              isPending={isMutationPending}
              taskStatus={task.status}
            />
            <TaskDueAtSelect
              onDateChange={handleUpdateTask}
              isPending={isMutationPending}
              dueAt={task.dueAt ?? undefined}
            />
          </div>

          <TaskDescription
            key={task.id}
            onSave={handleUpdateTask}
            taskDescription={task.description}
          />

          <div className="my-5 h-[1px] w-full bg-black/5" />
          <Subtasks
            subtasks={task.subtasks}
            createTask={createTask}
            taskId={id}
            updateSubtaskTitle={handleUpdateSubtaskTitle}
          />
        </div>

        <div className="mx-auto flex w-full grow flex-col p-5 @2xl/task:max-w-[44rem] @2xl/task:flex-1 @2xl/task:overflow-hidden">
          <div
            id="chat-overlay"
            className={cn(
              'flex-1 overflow-hidden rounded-2xl border-[0.5px] border-solid border-film-normal bg-mono-paper-darker shadow-just-shadow',
              {
                'flex flex-col': isChatOverlayVisible,
                hidden: !isChatOverlayVisible,
              }
            )}
          />
          {!isChatOverlayVisible && (
            <div className="flex flex-1 flex-col overflow-hidden">
              {task.thread && (
                <div className="flex flex-1 flex-col overflow-hidden pt-5">
                  <ChatView taskId={id} />
                </div>
              )}
            </div>
          )}
        </div>

        {!isRenderedInStackCard && (
          <DeleteTaskPopover
            taskId={task.id}
            parentTaskId={task.parentTask?.id}
          />
        )}
      </div>
    )
  }
)

TaskThread.displayName = 'TaskThread'

const SuspendedTaskThread = forwardRef<HTMLDivElement, TaskThreadProperties>(
  ({ className, isChatOverlayVisible, ...properties }, reference) => {
    const skeleton = useMemo(
      () => (
        <div
          className={cn(
            'flex h-full flex-1 grow flex-col overflow-auto @2xl/task:flex-row @2xl/task:overflow-hidden [&[data-state="inactive"]]:hidden',
            className
          )}
          data-testid="task-thread-skeleton"
        >
          <div className="w-full max-w-[44rem] border-film-subtle px-5 pb-5 @2xl/task:w-1/2 @2xl/task:flex-1 @2xl/task:overflow-y-auto @2xl/task:border-r">
            <div className="flex gap-2 py-4">
              <Skeleton className="h-8 flex-1 bg-film-subtle" />
              <Skeleton className="h-8 flex-1 bg-film-subtle" />
              <Skeleton className="h-8 flex-1 bg-film-subtle" />
            </div>
            <Skeleton className="h-9 w-full bg-film-subtle" />
            <div className="my-5 h-[1px] w-full bg-black/5" />
            <Skeleton className="h-36 w-full bg-film-subtle" />
          </div>
          <div className="mx-auto flex w-full grow flex-col justify-end p-5 @2xl/task:max-w-[44rem] @2xl/task:flex-1 @2xl/task:overflow-hidden">
            <Skeleton className="h-14 w-full bg-film-subtle" />
          </div>
        </div>
      ),
      [className]
    )

    return (
      <SmartSuspense fallback={skeleton} fallbackMinDurationMs={300}>
        <TaskThread
          ref={reference}
          className={className}
          isChatOverlayVisible={isChatOverlayVisible}
          {...properties}
        />
      </SmartSuspense>
    )
  }
)

SuspendedTaskThread.displayName = 'SuspendedTaskThread'

export { SuspendedTaskThread as TaskThread }
