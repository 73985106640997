import { IconComponent } from '@/lib/types'

export const MessageBubbleIcon: IconComponent = ({ ...properties }) => {
  return (
    <svg
      width="24"
      height="34"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...properties}
    >
      <path
        d="M4 3C2.91146 3 2 3.90625 2 5V17C2 18.0937 2.91146 19 4 19H18L22 23L21.9896 5C21.9896 3.90625 21.0833 3 19.9896 3H4ZM4 5H19.9896L20 18.1667L18.8281 17H4V5Z"
        fill="currentColor"
      />
    </svg>
  )
}

MessageBubbleIcon.displayName = 'MessageBubbleIcon'
