import * as CheckboxPrimitive from '@radix-ui/react-checkbox'
import React, { useCallback } from 'react'

import { CheckboxIcon } from '@/components/icons/CheckboxIcon'
import { CircleCrossFilledIcon } from '@/components/icons/CircleCrossFilledIcon'
import { TaskStatus } from '@/gql/generated/graphql'
import { cn } from '@/lib/utils'

interface Properties
  extends React.ComponentPropsWithoutRef<typeof CheckboxPrimitive.Root> {
  id: string
  status: TaskStatus
  label?: string
  onTaskStatusChange: (status: TaskStatus) => void
  className?: string
}

export const TaskStatusCheckbox = ({
  className,
  id,
  label,
  onTaskStatusChange,
  status,
  ...rest
}: Properties) => {
  const handleClick = useCallback(
    (event: React.MouseEvent<HTMLButtonElement>) => {
      if (event.altKey) {
        onTaskStatusChange('CANCELLED')
      } else {
        if (status === 'COMPLETED') {
          onTaskStatusChange('OPEN')
        } else {
          onTaskStatusChange('COMPLETED')
        }
      }
    },
    [onTaskStatusChange, status]
  )

  return (
    <div
      className={cn('flex items-center space-x-2 overflow-hidden', className)}
    >
      <CheckboxPrimitive.Root
        id={id}
        className={cn(
          'relative flex h-[15px] w-[15px] min-w-[15px] items-center justify-center overflow-hidden rounded-full',
          'focus:border-spacing-1 focus:bg-sky-50 focus:outline-sky-300'
        )}
        onClick={handleClick}
        {...rest}
      >
        {status === 'COMPLETED' ? (
          <>
            <CheckboxPrimitive.Indicator tabIndex={-1} />
            <CheckboxIcon className="size-5 shrink-0 text-black" />
          </>
        ) : status === 'CANCELLED' ? (
          <>
            <CheckboxPrimitive.Indicator tabIndex={-1} />
            <CircleCrossFilledIcon className="size-5 shrink-0 text-mono-ink-subtle" />
          </>
        ) : (
          <>
            <CheckboxPrimitive.Indicator tabIndex={-1} />
            <div className="absolute h-[15px] w-[15px] shrink-0 rounded-full border-2 border-mono-ink-subtle" />
          </>
        )}
      </CheckboxPrimitive.Root>
      {label && (
        <label className="overflow-hidden text-ellipsis text-nowrap">
          {label}
        </label>
      )}
    </div>
  )
}

TaskStatusCheckbox.displayName = 'TaskStatusCheckbox'
