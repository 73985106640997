import { IconComponent } from '@/lib/types'

export const PasteClipboardIcon: IconComponent = ({ ...properties }) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...properties}
  >
    <path
      d="M10.5417 3.25C9.58468 3.25 8.79475 3.98568 8.68841 4.91667H7.20837C6.17973 4.91667 5.33337 5.76302 5.33337 6.79167V18.0417C5.33337 19.0703 6.17973 19.9167 7.20837 19.9167H16.7917C17.8204 19.9167 18.6667 19.0703 18.6667 18.0417V6.79167C18.6667 5.76302 17.8204 4.91667 16.7917 4.91667H15.3117C15.2053 3.98568 14.4154 3.25 13.4584 3.25H10.5417ZM10.5417 4.5H13.4584C13.8121 4.5 14.0834 4.77127 14.0834 5.125C14.0834 5.47873 13.8121 5.75 13.4584 5.75H10.5417C10.188 5.75 9.91671 5.47873 9.91671 5.125C9.91671 4.77127 10.188 4.5 10.5417 4.5ZM7.20837 6.16667H8.98789C9.32643 6.66797 9.89718 7 10.5417 7H13.4584C14.1029 7 14.6737 6.66797 15.0122 6.16667H16.7917C17.1454 6.16667 17.4167 6.43793 17.4167 6.79167V18.0417C17.4167 18.3954 17.1454 18.6667 16.7917 18.6667H7.20837C6.85464 18.6667 6.58337 18.3954 6.58337 18.0417V6.79167C6.58337 6.43793 6.85464 6.16667 7.20837 6.16667Z"
      fill="currentColor"
    />
    <path
      d="M11.9891 10.3248C11.6463 10.3292 11.3707 10.6135 11.375 10.9585V14.0336L10.3594 13.0158C10.2009 12.8531 9.96874 12.7879 9.74956 12.8444C9.53037 12.9008 9.35893 13.0722 9.30251 13.2914C9.24609 13.5106 9.31119 13.7428 9.47395 13.9012L11.5052 15.9303C11.6224 16.0844 11.8068 16.1755 12.0022 16.1755C12.1975 16.1734 12.3819 16.0822 12.4991 15.926L14.526 13.9012C14.6888 13.7428 14.7539 13.5106 14.6975 13.2914C14.6411 13.0722 14.4696 12.9008 14.2504 12.8444C14.0312 12.7879 13.799 12.8531 13.6406 13.0158L12.625 14.0336V10.9585C12.6272 10.7892 12.5621 10.6265 12.4427 10.5071C12.3212 10.3878 12.1584 10.3227 11.9891 10.3248Z"
      fill="currentColor"
    />
  </svg>
)

PasteClipboardIcon.displayName = 'PasteClipboardIcon'
