import { IconComponent } from '@/lib/types'

export const ArrowDownIcon: IconComponent = ({ ...properties }) => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...properties}
    >
      <path
        d="M10.1974 2.49132C9.85455 2.49567 9.57894 2.77995 9.58328 3.12501V15.3668L4.81766 10.599C4.65924 10.4362 4.42703 10.3711 4.20785 10.4275C3.98867 10.4839 3.81723 10.6554 3.7608 10.8746C3.70438 11.0938 3.76948 11.326 3.93224 11.4844L9.76558 17.3177C10.0108 17.5608 10.4058 17.5608 10.651 17.3177L16.4843 11.4844C16.6471 11.326 16.7122 11.0938 16.6558 10.8746C16.5993 10.6554 16.4279 10.4839 16.2087 10.4275C15.9895 10.3711 15.7573 10.4362 15.5989 10.599L10.8333 15.3668V3.12501C10.8355 2.95573 10.7704 2.79297 10.651 2.67362C10.5295 2.55426 10.3667 2.48915 10.1974 2.49132Z"
        fill="currentColor"
      />
    </svg>
  )
}

ArrowDownIcon.displayName = 'ArrowDownIcon'
