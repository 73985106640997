import { useMutation, UseMutationOptions } from '@tanstack/react-query'
import { useCallback, useMemo } from 'react'

import { createTaskQueryKey } from '@/contexts/ApiProvider'
import { useTaskDetailPageContext } from '@/features/task/hooks/useTaskDetailPageContext'
import { RefineTaskInput, RefineTaskMutation } from '@/gql/generated/graphql'

export const useRefineTaskMutation = (
  taskId: string,
  parameters: Partial<
    UseMutationOptions<RefineTaskMutation, Error, RefineTaskInput, unknown>
  >
) => {
  const { api } = useTaskDetailPageContext()

  const taskQueryKey = useMemo(() => createTaskQueryKey(taskId), [taskId])
  const mutationKey = useMemo(() => [...taskQueryKey, 'refine'], [taskQueryKey])

  const {
    data,
    isPending: refineTaskPending,
    mutate: refineTaskMutation,
    ...restMutationProperties
  } = useMutation({
    mutationFn: api.refineTask,
    mutationKey,
    ...parameters,
  })

  const refineTask = useCallback(
    (refineTaskInput?: Omit<RefineTaskInput, 'taskId'>) => {
      refineTaskMutation({
        ...refineTaskInput,
        taskId,
      })
    },
    [refineTaskMutation, taskId]
  )

  return {
    refineTask,
    refineTaskPending,
    taskRefinement: data?.refineTask,
    ...restMutationProperties,
  }
}
