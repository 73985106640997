import { VariantProps, cva } from 'class-variance-authority'
import { FC, HTMLAttributes } from 'react'

import { cn } from '@/lib/utils'

const variants = cva('flex', {
  defaultVariants: {
    activity: 'inActive',
    size: 'large',
    variant: 'naked',
  },
  variants: {
    activity: {
      active: 'bg-sky-50 border border-sky-300 text-blue-ink-strong',
      inActive: 'border border-transparent',
    },
    size: {
      large: 'py-2.5 gap-2.5 rounded-lg',
      medium: 'p-1 gap-1 rounded-md text-sm',
    },
    variant: {
      naked: '',
    },
  },
})

interface Properties
  extends HTMLAttributes<HTMLDivElement>,
    VariantProps<typeof variants> {}

export const Casing: FC<Properties> = ({
  activity,
  children,
  className,
  size,
  variant,
  ...properties
}) => {
  return (
    <div
      className={cn(variants({ activity, size, variant }), className)}
      {...properties}
    >
      {children}
    </div>
  )
}
Casing.displayName = 'Casing'
