import { useNavigate, useRouter, useSearch } from '@tanstack/react-router'
import { AnimatePresence, motion } from 'framer-motion'
import { useCallback } from 'react'
import usePrevious from 'react-use/lib/usePrevious'

import { CrossMediumIcon } from '@/components/icons/CrossMediumIcon'
import { ExpandArrowsIcon } from '@/components/icons/ExpandArrowsIcon'
import { Button } from '@/components/ui/button/Button'
import { TaskHeader } from '@/features/task/components/TaskHeader'
import { TaskThread } from '@/features/task/components/TaskThread'
import { useUpdateTask } from '@/features/task/hooks/useUpdateTask'
import { UpdateTaskInput } from '@/gql/generated/graphql'
import { useDocumentKeyCapture } from '@/lib/hooks/useDocumentKeyCapture'

const StackCard = ({
  closeTask,
  haveLengthChanged,
  index,
  stackLength,
  taskId,
}: {
  taskId: string
  index: number
  stackLength: number
  haveLengthChanged: boolean
  closeTask: (taskId: string) => void
}) => {
  const navigate = useNavigate()
  const handleCloseTask = useCallback(() => {
    closeTask(taskId)
  }, [closeTask, taskId])
  const { updateTask } = useUpdateTask(taskId)

  const handleUpdateTask = useCallback(
    (data: Partial<UpdateTaskInput>) => {
      updateTask({
        ...data,
        id: taskId ?? '',
      })
    },
    [taskId, updateTask]
  )

  const handleExpandTask = useCallback(() => {
    void navigate({
      params: { taskId },
      to: '/all-work/$taskId',
    })
  }, [navigate, taskId])

  return (
    <motion.div
      initial={{
        right:
          // eslint-disable-next-line unicorn/no-negated-condition
          !haveLengthChanged
            ? 0
            : index === 0 && stackLength > 1
              ? '20%'
              : '-100%',
      }}
      animate={{
        right:
          stackLength - 1 === index
            ? 0
            : stackLength - 2 === index
              ? '19.5%'
              : '20%',
      }}
      exit={{ right: '-100%' }}
      transition={{ bounce: 0, duration: 0.5, type: 'spring' }}
      key={taskId}
      className="fixed top-0 z-40 flex h-full w-[42rem] flex-col p-5 pl-0"
    >
      <div
        className="flex w-full flex-1 flex-col overflow-hidden rounded-3xl border border-film-subtle bg-mono-paper-darker drop-shadow-lg @container/task"
        data-testid="task-stack-card"
      >
        <div className="flex justify-between p-2.5">
          <Button
            variant={'subtle'}
            className="rounded-full"
            size="sm"
            onClick={handleCloseTask}
            data-testid="close-task-button"
          >
            <CrossMediumIcon className="size-4" />
            <span className="pr-1">Close</span>
          </Button>
          <Button
            variant={'subtle'}
            className="rounded-full"
            size="sm"
            onClick={handleExpandTask}
            data-testid="expand-task-button"
          >
            <ExpandArrowsIcon className="size-4" />
            <span className="pr-1">Expand</span>
          </Button>
        </div>
        <TaskHeader
          isFlowChartEnabled={false}
          onSubmit={handleUpdateTask}
          hideTabs
          taskId={taskId}
        />
        <TaskThread
          id={taskId}
          isChatOverlayVisible={false}
          isRenderedInStackCard={true}
          data-testid="thread-tab-content"
          className="px-12"
        />
      </div>
    </motion.div>
  )
}

StackCard.displayName = 'StackCard'

export const TaskStack = () => {
  const { stack } = useSearch({
    from: '/_private',
  })
  const router = useRouter()
  const previousStack = usePrevious(stack)

  const navigate = useNavigate()
  const closeTask = useCallback(
    (taskToCloseId: string) => {
      const { stack, ...rest } = router.state.location.search
      const updatedStack = stack?.filter((id) => id !== taskToCloseId)

      void navigate({
        search: updatedStack?.length ? { ...rest, stack: updatedStack } : rest,
        to: '.',
      })
    },
    [navigate, router]
  )

  const haveLengthChanged = stack?.length !== previousStack?.length
  const stacksInView = stack?.slice(-3)
  const topLevelTaskId = stack?.at(-1)

  useDocumentKeyCapture(
    'Escape',
    useCallback(
      ({ event }) => {
        const radixPopperContentWrapper = document.querySelectorAll(
          '[data-radix-popper-content-wrapper]'
        )
        const hasOpenPopper = radixPopperContentWrapper.length > 0
        const isDialogOpen =
          document.querySelectorAll('[role="alertdialog"]').length > 0

        if (
          event.target instanceof HTMLInputElement ||
          event.target instanceof HTMLTextAreaElement ||
          (event.target instanceof HTMLDivElement &&
            event.target.getAttribute('contenteditable') === 'true') ||
          hasOpenPopper ||
          isDialogOpen
        ) {
          return
        }
        if (topLevelTaskId) {
          closeTask(topLevelTaskId)
        }
      },
      [closeTask, topLevelTaskId]
    ),
    true,
    'capture'
  )

  return (
    <AnimatePresence>
      {stacksInView?.map((taskId, index) => {
        return (
          <StackCard
            key={taskId}
            taskId={taskId}
            index={index}
            haveLengthChanged={haveLengthChanged}
            stackLength={stacksInView.length}
            closeTask={closeTask}
          />
        )
      })}
    </AnimatePresence>
  )
}

TaskStack.displayName = 'TaskStack'
