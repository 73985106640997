import { useEffect } from 'react'

const DEFAULT_PAGE_TITLE = 'Interloom'

export const useDocumentTitle = (title?: string) => {
  useEffect(() => {
    document.title = title
      ? `${title} · ${DEFAULT_PAGE_TITLE}`
      : DEFAULT_PAGE_TITLE

    return () => {
      document.title = DEFAULT_PAGE_TITLE
    }
  }, [title])
}
