import { motion } from 'framer-motion'
import { useCallback, useMemo, useState } from 'react'
import { v7 as uuid } from 'uuid'

import { ApproveIcon } from '@/components/icons/ApproveIcon'
import { CancelCrossIcon } from '@/components/icons/CancelCrossIcon'
import { CommentsChatSwitchBubbleIcon } from '@/components/icons/CommentsChatSwitchBubbleIcon'
import { PlusMathIcon } from '@/components/icons/PlusMathIcon'
import { SwitchesTogglesIcon } from '@/components/icons/SwitchesTogglesIcon'
import { Button } from '@/components/ui/button/Button'
import * as Popover from '@/components/ui/popover/Popover'
import { useChatOverlay } from '@/features/task/components/ChatOverlayProvider'
import { useCreateChatMessage } from '@/features/task/components/chat/useCreateChatMessage'
import { Subtask } from '@/features/task/components/subtasks/Subtask'
import { SubtasksPlanningFeedbackForm } from '@/features/task/components/subtasks/SubtasksPlanningFeedbackForm'
import { SubtasksView } from '@/features/task/components/subtasks/SubtasksView'
import { useApplyRefinement } from '@/features/task/hooks/useApplyRefinement'
import { useTask } from '@/features/task/hooks/useTask'
import { staggerMotionVariants } from '@/features/task/utils/constants'
import { RefineTaskInput, RefineTaskMutation } from '@/gql/generated/graphql'

const actionButton = (
  <div className="rounded-sm bg-green-100 p-1.5">
    <PlusMathIcon className="size-3" />
  </div>
)

interface SubtasksPlanningProperties {
  initialTaskRefinement?: RefineTaskMutation['refineTask']
  onRefineTask: (input?: Omit<RefineTaskInput, 'taskId'>) => void
  setNotPlanning: () => void
  taskRefinement?: RefineTaskMutation['refineTask']
  taskId: string
}

export const SubtasksPlanning = ({
  initialTaskRefinement,
  onRefineTask,
  setNotPlanning,
  taskId,
  taskRefinement,
}: SubtasksPlanningProperties) => {
  const chatOverlay = useChatOverlay()
  const [feedbackFormOpen, onFeedbackOpenChange] = useState(false)
  const { task } = useTask(taskId)
  const threadId = task?.thread?.id

  const { isPending: applyTaskRefinementPending, mutate: applyTaskRefinement } =
    useApplyRefinement({
      onSuccess: setNotPlanning,
      taskId,
      taskRefinementId: taskRefinement?.id ?? '',
    })

  const { createNewMessage } = useCreateChatMessage({})

  const acceptPlan = useCallback(() => {
    const refinementId = taskRefinement?.id ?? initialTaskRefinement?.id
    chatOverlay.hideChatOverlay()
    if (!refinementId) {
      return
    }
    applyTaskRefinement(refinementId)
  }, [
    applyTaskRefinement,
    chatOverlay,
    initialTaskRefinement?.id,
    taskRefinement?.id,
  ])

  const discardPlan = useCallback(() => {
    chatOverlay.hideChatOverlay()
    setNotPlanning()
  }, [chatOverlay, setNotPlanning])

  const proposedSubtasks = (
    taskRefinement ?? initialTaskRefinement
  )?.updates.map((update) => ({
    ...update.change,
    id: uuid(),
  }))

  const closeFeedbackForm = useCallback(() => {
    onFeedbackOpenChange(false)
  }, [])

  const handleFeedbackSubmit = useCallback(
    (feedback: string) => {
      const refinementId = taskRefinement?.id ?? initialTaskRefinement?.id
      closeFeedbackForm()
      if (!refinementId) {
        return
      }

      onRefineTask({
        previousTaskRefinementFeedback: {
          taskRefinementId: refinementId,
          userFeedback: [feedback],
        },
      })

      if (threadId) {
        void createNewMessage({
          body: { text: feedback },
          threadId,
        })
      }
    },
    [
      closeFeedbackForm,
      createNewMessage,
      initialTaskRefinement?.id,
      onRefineTask,
      taskRefinement?.id,
      threadId,
    ]
  )

  const body = useMemo(
    () => (
      <div className="flex flex-col gap-3">
        <ul
          className="rounded-lg border border-film-subtle bg-mono-paper shadow"
          data-testid="subtask-list"
        >
          {proposedSubtasks?.map((subtask, index) => {
            return (
              <motion.li
                key={subtask.id}
                custom={[index, index + 1 === proposedSubtasks.length]}
                variants={staggerMotionVariants}
                initial="hidden"
                animate="visible"
              >
                <Subtask taskId={subtask.id} action={actionButton}>
                  {subtask.title}
                </Subtask>
              </motion.li>
            )
          })}
        </ul>
      </div>
    ),
    [proposedSubtasks]
  )
  const sharedButtonProperties = useMemo(
    () => ({
      className:
        'flex-1 border hover:shadow focus:border-sky-500 focus:bg-sky-100 focus:text-sky-600 focus:shadow active:bg-sky-100 active:opacity-75',
      size: 'sm' as const,
    }),
    []
  )

  const footer = useMemo(
    () => (
      <div className="flex animate-fadeIn items-center justify-start gap-3 pb-2 pt-4">
        <Popover.Popover
          open={feedbackFormOpen}
          onOpenChange={onFeedbackOpenChange}
        >
          <Popover.PopoverTrigger asChild>
            <Button
              data-testid="button-plan-feedback"
              aria-label="Give plan feedback"
              {...sharedButtonProperties}
            >
              <CommentsChatSwitchBubbleIcon className="size-5" />
            </Button>
          </Popover.PopoverTrigger>
          <Popover.PopoverPortal>
            <Popover.PopoverContent data-testid="plan-feeback-popover">
              <SubtasksPlanningFeedbackForm
                onSubmit={handleFeedbackSubmit}
                onCancel={closeFeedbackForm}
              />
            </Popover.PopoverContent>
          </Popover.PopoverPortal>
        </Popover.Popover>
        <Button
          data-testid="button-edit-plan-sources"
          onClick={chatOverlay.showChatOverlay}
          disabled={chatOverlay.isChatOverlayVisible}
          aria-label="Edit Proposal"
          {...sharedButtonProperties}
        >
          <SwitchesTogglesIcon className="size-5" />
        </Button>
        <Button
          data-testid="button-discard-plan"
          onClick={discardPlan}
          disabled={applyTaskRefinementPending}
          aria-label="Discard Proposal"
          {...sharedButtonProperties}
        >
          <CancelCrossIcon className="size-5" />
        </Button>
        <Button
          data-testid="button-accept-plan"
          onClick={acceptPlan}
          variant={'solidIndigo'}
          disabled={applyTaskRefinementPending}
          aria-label="Accept Proposal"
          {...sharedButtonProperties}
        >
          <ApproveIcon className="size-5" />
        </Button>
      </div>
    ),
    [
      acceptPlan,
      applyTaskRefinementPending,
      closeFeedbackForm,
      discardPlan,
      feedbackFormOpen,
      handleFeedbackSubmit,
      chatOverlay.isChatOverlayVisible,
      sharedButtonProperties,
      chatOverlay.showChatOverlay,
    ]
  )

  return (
    <SubtasksView
      containerClassName="border-indigo-300 bg-gradient-indigo"
      heading={
        <>
          <div className="flex items-center gap-1 text-xs font-medium uppercase text-indigo-600">
            <span className="px-2">Plan</span>
            <span className="block rounded-md bg-indigo-200 px-2.5 py-1">
              {(taskRefinement ?? initialTaskRefinement)?.precedentTasks
                .length === 0
                ? 'Ungrounded '
                : 'Review '}
              · {proposedSubtasks?.length} step
              {proposedSubtasks?.length === 1 ? '' : 's'} added
            </span>
          </div>

          <span className="block h-8" />
        </>
      }
      body={body}
      footer={footer}
    />
  )
}

SubtasksPlanning.displayName = 'SubtasksPlanning'
