import { IconComponent } from '@/lib/types'

export const ThreadIcon: IconComponent = ({ ...properties }) => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...properties}
    >
      <path
        d="M4.37508 2.08337C2.88637 2.08337 1.66675 3.30299 1.66675 4.79171V13.9388C1.66675 14.7657 2.6802 15.2822 3.35078 14.7939L5.03914 13.5656C5.25182 14.8503 6.3651 15.8334 7.70842 15.8334H12.9211L16.6516 18.5439C16.836 18.6806 17.053 18.7479 17.2722 18.7479C17.4371 18.7479 17.6021 18.711 17.754 18.6329C18.1121 18.4506 18.3334 18.0881 18.3334 17.6888V8.54171C18.3334 7.04865 17.1181 5.83337 15.6251 5.83337H15.0001V4.79171C15.0001 3.30299 13.7805 2.08337 12.2917 2.08337H4.37508ZM4.37508 3.33337H12.2917C13.1034 3.33337 13.7501 3.98008 13.7501 4.79171V9.37504C13.7501 10.1867 13.1034 10.8334 12.2917 10.8334H6.87508C6.7427 10.8334 6.61467 10.8746 6.50833 10.9527L2.91675 13.5656V4.79171C2.91675 3.98008 3.56345 3.33337 4.37508 3.33337ZM5.62508 5.00004C5.39939 4.99787 5.19105 5.11506 5.07604 5.31037C4.96319 5.50568 4.96319 5.7444 5.07604 5.93971C5.19105 6.13502 5.39939 6.25221 5.62508 6.25004H11.0417C11.2674 6.25221 11.4758 6.13502 11.5908 5.93971C11.7036 5.7444 11.7036 5.50568 11.5908 5.31037C11.4758 5.11506 11.2674 4.99787 11.0417 5.00004H5.62508ZM15.0001 7.08337H15.6251C16.4302 7.08337 17.0834 7.73659 17.0834 8.54171V17.3156L13.4918 14.7027C13.3855 14.6246 13.2575 14.5834 13.1251 14.5834H7.70842C6.90329 14.5834 6.25008 13.9302 6.25008 13.125V12.6845L7.07908 12.0834H12.2917C13.7805 12.0834 15.0001 10.8638 15.0001 9.37504V7.08337ZM5.62508 7.91671C5.39939 7.91454 5.19105 8.03172 5.07604 8.22704C4.96319 8.42235 4.96319 8.66107 5.07604 8.85638C5.19105 9.05169 5.39939 9.16888 5.62508 9.16671H9.79175C10.0174 9.16888 10.2258 9.05169 10.3408 8.85638C10.4536 8.66107 10.4536 8.42235 10.3408 8.22704C10.2258 8.03172 10.0174 7.91454 9.79175 7.91671H5.62508Z"
        fill="currentColor"
      />
    </svg>
  )
}

ThreadIcon.displayName = 'ThreadIcon'
