import { PopperContentProps } from '@radix-ui/react-popper'
import { VariantProps } from 'class-variance-authority'

import { Avatar } from '@/components/ui/avatar/Avatar'
import Select, { triggerVariants } from '@/components/ui/select/select'
import { UnassignedIcon } from '@/features/task/components/UnassignedIcon'
import { FetchAllUsersQuery, UpdateTaskInput } from '@/gql/generated/graphql'

interface TaskAssigneeProperties extends VariantProps<typeof triggerVariants> {
  assigneeId?: string | null
  disabled?: boolean
  onSelect: (formData: Pick<UpdateTaskInput, 'assigneeId'>) => void
  users: FetchAllUsersQuery['users']
  className?: string
  side?: PopperContentProps['side']
}

export const TaskAssignee = ({
  assigneeId,
  className,
  disabled = false,
  onSelect,
  side,
  users,
  variant,
}: TaskAssigneeProperties) => {
  const assignee = users.find((user) => user.id === assigneeId)

  const handleSelect = (assigneeId: string) => {
    onSelect({
      // eslint-disable-next-line unicorn/no-null
      assigneeId: assigneeId === 'unassigned' ? null : assigneeId,
    })
  }

  return (
    <Select.Root
      disabled={disabled}
      onValueChange={handleSelect}
      value={assignee?.id ?? ''}
    >
      <Select.Trigger
        className={className}
        data-testid="dropdown-assignee-picker"
        variant={variant}
        icon={
          assignee ? (
            <Avatar
              alt={assignee.name}
              src={assignee.avatarUrl}
              className="size-6 rounded-full"
            />
          ) : (
            <UnassignedIcon className="size-6 min-h-6 min-w-6" />
          )
        }
        placeholder={'Assign'}
      />

      <Select.Content data-testid="dropdown-assignee-viewport" side={side}>
        <div className="mb-1' border-b border-film-subtle pb-1">
          <Select.Item
            Icon={UnassignedIcon}
            text={'No assignee'}
            value={'unassigned'}
            forceChecked={!assignee}
            data-testid="dropdown-assignee-people-unassigned"
          />
        </div>
        <div className="mb-1 mt-1.5 px-2 text-xs-bold text-mono-ink-subtle">
          People
        </div>

        <div className="flex grow flex-col gap-1 overflow-y-auto">
          {users.map((user) => (
            <Select.Item
              data-testid={`dropdown-assignee-people-${user.id}`}
              key={user.id}
              Icon={() => (
                <Avatar
                  alt={user.name}
                  src={user.avatarUrl}
                  className="size-6 rounded-full"
                  data-testid={`dropdown-assignee-people-${user.id}-avatar`}
                />
              )}
              text={user.name}
              value={user.id}
            />
          ))}
        </div>
      </Select.Content>
    </Select.Root>
  )
}

TaskAssignee.displayName = 'TaskAssignee'
