import { IconComponent } from '@/lib/types'

export const PlusMathIcon: IconComponent = ({ ...properties }) => {
  return (
    <svg
      fill="none"
      height="24"
      viewBox="0 0 24 24"
      width="24"
      xmlns="http://www.w3.org/2000/svg"
      {...properties}
    >
      <path d="M11 3V11H3V13H11V21H13V13H21V11H13V3H11Z" fill="currentColor" />
    </svg>
  )
}

PlusMathIcon.displayName = 'PlusMathIcon'
