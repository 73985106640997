import { IconComponent } from '@/lib/types'

export const CommentsChatSwitchBubbleIcon: IconComponent = ({
  ...properties
}) => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...properties}
    >
      <path
        d="M4.37502 2.91602C2.8863 2.91602 1.66669 4.13563 1.66669 5.62435V12.7077C1.66669 14.1964 2.8863 15.416 4.37502 15.416H5.00002V17.7077C5.00002 18.5258 6.0113 19.0315 6.66669 18.541L10.8334 15.416H15.625C17.1137 15.416 18.3334 14.1964 18.3334 12.7077V5.62435C18.3334 4.13563 17.1137 2.91602 15.625 2.91602H4.37502ZM4.37502 4.16602H15.625C16.4367 4.16602 17.0834 4.81272 17.0834 5.62435V12.7077C17.0834 13.5193 16.4367 14.166 15.625 14.166H10.625C10.4905 14.166 10.3581 14.2094 10.2496 14.2919L6.25002 17.291V14.791C6.25002 14.446 5.97007 14.166 5.62502 14.166H4.37502C3.56339 14.166 2.91669 13.5193 2.91669 12.7077V5.62435C2.91669 4.81272 3.56339 4.16602 4.37502 4.16602ZM6.45835 7.08268C6.23266 7.07834 6.02433 7.1977 5.90931 7.39084C5.79646 7.58615 5.79646 7.82704 5.90931 8.02235C6.02433 8.21766 6.23266 8.33485 6.45835 8.33268H13.5417C13.7674 8.33485 13.9757 8.21766 14.0907 8.02235C14.2036 7.82704 14.2036 7.58615 14.0907 7.39084C13.9757 7.1977 13.7674 7.07834 13.5417 7.08268H6.45835ZM6.45835 9.99935C6.23266 9.99501 6.02433 10.1144 5.90931 10.3075C5.79646 10.5028 5.79646 10.7437 5.90931 10.939C6.02433 11.1343 6.23266 11.2515 6.45835 11.2493H11.875C12.1007 11.2515 12.309 11.1343 12.4241 10.939C12.5369 10.7437 12.5369 10.5028 12.4241 10.3075C12.309 10.1144 12.1007 9.99501 11.875 9.99935H6.45835Z"
        fill="currentColor"
      />
    </svg>
  )
}

CommentsChatSwitchBubbleIcon.displayName = 'CommentsChatSwitchBubbleIcon'
