import { useCallback } from 'react'

export const useOnKeyDown = <T extends HTMLElement>(
  key: string,
  callback: (event: React.KeyboardEvent<T>) => void
) => {
  const onKeyDown = useCallback(
    (event: React.KeyboardEvent<T>) => {
      if (event.key === key) {
        callback(event)
      }
    },
    [callback, key]
  )

  return onKeyDown
}
