import { useMutation, useQueryClient } from '@tanstack/react-query'
import { useMemo } from 'react'
import { toast } from 'react-toastify'

import { createTaskQueryKey, useApi } from '@/contexts/ApiProvider'
import { logger } from '@/lib/logger'

interface UseApplyTaskMutationProperties {
  onSuccess?: () => void
  taskId: string
  taskRefinementId: string
}

export const useApplyRefinement = ({
  onSuccess,
  taskId,
  taskRefinementId,
}: UseApplyTaskMutationProperties) => {
  const api = useApi()
  const taskQueryKey = useMemo(() => createTaskQueryKey(taskId), [taskId])
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: api.applyTaskRefinement,
    mutationKey: ['refinement', taskRefinementId, 'apply'],
    onError(error) {
      logger.error(error)
      toast.error('Something went wrong!', {
        toastId: 'Something went wrong!',
      })
    },
    onSuccess() {
      void queryClient.invalidateQueries({ queryKey: taskQueryKey })
      onSuccess?.()
    },
  })
}
