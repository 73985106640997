import { IconComponent } from '@/lib/types'

export const CancelCrossIcon: IconComponent = ({ ...properties }) => {
  return (
    <svg
      width="25"
      height="24"
      viewBox="0 0 25 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...properties}
    >
      <path
        d="M12.5 2C6.98698 2 2.5 6.48698 2.5 12C2.5 17.513 6.98698 22 12.5 22C18.013 22 22.5 17.513 22.5 12C22.5 6.48698 18.013 2 12.5 2ZM12.5 3.5C17.2031 3.5 21 7.29688 21 12C21 16.7031 17.2031 20.5 12.5 20.5C7.79688 20.5 4 16.7031 4 12C4 7.29688 7.79688 3.5 12.5 3.5ZM15.7422 7.98958C15.5443 7.99479 15.3568 8.07552 15.2188 8.21875L12.5 10.9401L9.78125 8.21875C9.63802 8.07552 9.44531 7.99219 9.24219 7.99219C8.9375 7.99219 8.66146 8.17708 8.54687 8.46094C8.43229 8.74219 8.5 9.06771 8.71875 9.28125L11.4401 12L8.71875 14.7188C8.52344 14.9089 8.44531 15.1875 8.51302 15.4505C8.58073 15.7135 8.78646 15.9193 9.04948 15.987C9.3125 16.0547 9.59115 15.9766 9.78125 15.7812L12.5 13.0599L15.2188 15.7812C15.4089 15.9766 15.6875 16.0547 15.9505 15.987C16.2135 15.9193 16.4193 15.7135 16.487 15.4505C16.5547 15.1875 16.4766 14.9089 16.2812 14.7188L13.5599 12L16.2812 9.28125C16.5026 9.0651 16.5729 8.73698 16.4531 8.45052C16.3333 8.16406 16.0521 7.98177 15.7422 7.98958Z"
        fill="currentColor"
      />
    </svg>
  )
}

CancelCrossIcon.displayName = 'CancelCrossIcon'
