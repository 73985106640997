import { useQueryClient } from '@tanstack/react-query'
import { useCallback, useMemo } from 'react'

import { useOrderSubtasks } from '@/features/task/hooks/useOrderSubtasks'
import { useUpdateTask } from '@/features/task/hooks/useUpdateTask'
import { FetchTasksByIdQuery, UpdateTaskInput } from '@/gql/generated/graphql'

type Task = FetchTasksByIdQuery['tasks'][number]

export const useMoveTask = (taskId: string) => {
  const queryClient = useQueryClient()
  const taskQueryKey = useMemo(() => ['task', taskId], [taskId])

  const orderSubtasksMutation = useOrderSubtasks(taskId, {
    orderType: 'orderByPlacingAfter',
  })

  const { updateTaskAsync } = useUpdateTask(taskId)

  const handleMove = useCallback(
    async (task: UpdateTaskInput, overId: string) => {
      await updateTaskAsync(task)

      if (task.parentTaskId && overId) {
        const previousTask = queryClient.getQueryData<Task>(['task', task.id])

        queryClient.setQueryData<Task>(taskQueryKey, (previousState) => {
          /* v8 ignore next */
          if (!previousState || !previousTask) return previousState

          return {
            ...previousState,
            subtasks: [...previousState.subtasks, previousTask],
          }
        })

        orderSubtasksMutation.mutate({
          activeId: task.id,
          insertAfterSubtaskWithId: overId,
          orderedSubtaskIds: [task.id],
          overId,
        })
      }
    },
    [updateTaskAsync, orderSubtasksMutation, queryClient, taskQueryKey]
  )

  return {
    moveTask: handleMove,
  }
}
