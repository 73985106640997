import { useMutation, useQueryClient } from '@tanstack/react-query'
import { toast } from 'react-toastify'

import { useApi } from '@/contexts/ApiProvider'
import { FetchTasksByIdQuery } from '@/gql/generated/graphql'
import { logger } from '@/lib/logger'

interface UseDeleteTaskProperties {
  taskId?: string
  onMutate?: () => void
  onError?: () => void
}

type Task = FetchTasksByIdQuery['tasks'][number]

export const useDeleteTask = (properties?: UseDeleteTaskProperties) => {
  const api = useApi()

  const queryKey = properties?.taskId ? ['task', properties.taskId] : ['tasks']
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: api.deleteTask,
    onMutate: async (taskId) => {
      const previousState = queryClient.getQueryData<Task[]>(queryKey)
      queryClient.setQueryData<Task | Task[]>(queryKey, (previousState) => {
        if (!previousState) return

        if (Array.isArray(previousState)) {
          return previousState?.filter(({ id }) => id !== taskId)
        }

        return {
          ...previousState,
          subtasks: previousState?.subtasks.filter(({ id }) => id !== taskId),
        }
      })
      properties?.onMutate?.()

      return { previousState }
    },
    // Partition: Important to keep these definitions below onMutate property so the TS compiler infers context properly
    onError: (error, _, context) => {
      queryClient.setQueryData(queryKey, context?.previousState)
      logger.error(error)
      toast.error('Task deletion failed!')
      properties?.onError?.()
    },
  })
}
