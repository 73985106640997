import { IconComponent } from '@/lib/types'

export const CircleCrossFilledIcon: IconComponent = ({ ...properties }) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...properties}
    >
      <path
        d="M12 2C6.47656 2 2 6.47656 2 12C2 17.5234 6.47656 22 12 22C17.5234 22 22 17.5234 22 12C22 6.47656 17.5234 2 12 2ZM15.7812 14.7188C16.0729 15.013 16.0729 15.487 15.7812 15.7812C15.6328 15.9271 15.4427 16 15.25 16C15.0573 16 14.8672 15.9271 14.7188 15.7812L12 13.0599L9.28125 15.7812C9.13281 15.9271 8.94271 16 8.75 16C8.55729 16 8.36719 15.9271 8.21875 15.7812C7.92708 15.487 7.92708 15.013 8.21875 14.7188L10.9401 12L8.21875 9.28125C7.92708 8.98698 7.92708 8.51302 8.21875 8.21875C8.51302 7.92708 8.98698 7.92708 9.28125 8.21875L12 10.9401L14.7188 8.21875C15.013 7.92708 15.487 7.92708 15.7812 8.21875C16.0729 8.51302 16.0729 8.98698 15.7812 9.28125L13.0599 12L15.7812 14.7188Z"
        fill="currentColor"
      />
    </svg>
  )
}

CircleCrossFilledIcon.displayName = 'CircleCrossFilledIcon'
