import { createFileRoute } from '@tanstack/react-router'
import { FC } from 'react'

import { TaskCircularCheckboxEmptyIcon } from '@/components/icons/TaskCircularCheckboxEmptyIcon'
import { Api } from '@/contexts/ApiProvider'
import { useNewTaskContext } from '@/contexts/NewTaskProvider'
import { TaskBreadcrumb } from '@/features/task/components/TaskBreadcrumb'
import { createUsersQueryOptions } from '@/features/task/hooks/useUsers'
import { createViewerQueryOptions } from '@/lib/hooks/useViewer'
import { cn } from '@/lib/utils'

export interface AllWorkNewSearch {
  assignToMe?: boolean
}

export interface AllWorkLoaderData {
  users: Awaited<ReturnType<Api['fetchAllUsers']>>
  viewer: Awaited<ReturnType<Api['fetchViewer']>>
}

export const Route = createFileRoute('/_private/all-work/new')({
  loader: async ({ context }): Promise<AllWorkLoaderData> => ({
    users: await context.queryClient.ensureQueryData(
      createUsersQueryOptions(context)
    ),
    viewer: await context.queryClient.ensureQueryData(
      createViewerQueryOptions(context)
    ),
  }),
  validateSearch: (search: Record<string, unknown>): AllWorkNewSearch => {
    if (search.assignToMe === true) {
      return {
        assignToMe: true,
      }
    }

    return {}
  },
})

const TaskDetailBreadcrumb: FC = () => {
  const { task } = useNewTaskContext()

  const newTaskTitle = 'New Task'
  const hasTitle = !!task.title
  const title: string = (hasTitle ? task.title : newTaskTitle) ?? ''

  return (
    <TaskBreadcrumb.Title
      Icon={TaskCircularCheckboxEmptyIcon}
      className="min-w-28 border border-dashed border-film-stronger"
      textClassName={cn(hasTitle ? '' : 'opacity-20')}
      title={title}
    />
  )
}
TaskDetailBreadcrumb.displayName = 'TaskDetailBreadcrumb'

Route.interloom = {
  Breadcrumb: TaskDetailBreadcrumb,
}
