import { useQueryClient } from '@tanstack/react-query'
import { useNavigate, useSearch } from '@tanstack/react-router'
import { useCallback } from 'react'

import { useApi } from '@/contexts/ApiProvider'
import { createTaskQueryOptions } from '@/features/task/hooks/useTask'

export const useTaskStack = (taskId: string, adjacentTasks?: string[]) => {
  const api = useApi()
  const queryClient = useQueryClient()
  const navigate = useNavigate()

  const search = useSearch({
    from: '/_private',
  })

  const previousStackItems = search?.stack
  const parentStackTask = previousStackItems?.[previousStackItems.length - 1]
  const isStackEmpty = !previousStackItems || previousStackItems.length === 0

  const navigateToTask = useCallback(() => {
    void navigate({
      params: { taskId },
      to: '/all-work/$taskId',
    })
  }, [navigate, taskId])

  const handleOpenStack = useCallback(() => {
    if (previousStackItems?.includes(taskId)) {
      void navigate({
        search: {
          ...search,
          stack: previousStackItems,
        },
        to: '.',
      })
      return
    }

    if (!parentStackTask) {
      void navigate({
        search: {
          ...search,
          stack: [taskId],
        },
        to: '.',
      })
      return
    }

    const queryOptions = createTaskQueryOptions({
      api,
      taskId: parentStackTask,
    })

    void queryClient.fetchQuery({ ...queryOptions }).then((data) => {
      const isValidStackBranch = Boolean(
        data.subtasks.some((subtask) => subtask.id === taskId)
      )

      if (isValidStackBranch) {
        void navigate({
          search: {
            ...search,
            stack: [...(search?.stack ?? []), taskId],
          },
          to: '.',
        })
      } else {
        if (adjacentTasks?.includes(parentStackTask)) {
          void navigate({
            search: {
              ...search,
              stack: [...previousStackItems.slice(0, -1), taskId],
            },
            to: '.',
          })
        } else {
          void navigate({
            search: {
              ...search,
              stack: [taskId],
            },
            to: '.',
          })
        }
      }
    })
  }, [
    api,
    adjacentTasks,
    navigate,
    parentStackTask,
    previousStackItems,
    queryClient,
    search,
    taskId,
  ])

  return { handleOpenStack, isStackEmpty, navigateToTask }
}
