import { IconComponent } from '@/lib/types'

export const ProcessPathIcon: IconComponent = ({ ...properties }) => {
  return (
    <svg
      fill="none"
      height="20"
      viewBox="0 0 20 20"
      width="20"
      xmlns="http://www.w3.org/2000/svg"
      {...properties}
    >
      <path
        d="M3.33334 1.66675C2.4132 1.66675 1.66667 2.41328 1.66667 3.33341C1.66667 4.25355 2.4132 5.00008 3.33334 5.00008C3.92796 5.00008 4.47917 4.68324 4.77431 4.16675H16.6667V9.16675H13.3333V7.1138C13.3333 6.8013 13.1641 6.51918 12.8863 6.37595C12.6129 6.23272 12.2787 6.25008 12.0269 6.42803L7.86025 9.31432C7.63455 9.47057 7.50001 9.72664 7.50001 10.0001C7.50001 10.2735 7.63455 10.5296 7.86025 10.6858L12.0269 13.5721C12.1701 13.6676 12.3351 13.7197 12.5 13.7197C12.6346 13.7197 12.7648 13.6893 12.8863 13.6242C13.1641 13.481 13.3333 13.1989 13.3333 12.8864V10.8334H18.3333V2.50008H4.77431C4.47483 1.98359 3.92796 1.66675 3.33334 1.66675ZM11.6667 8.70234V11.2978L9.79601 10.0001L11.6667 8.70234ZM1.66667 9.16675V10.8334H3.33334V9.16675H1.66667ZM5.00001 9.16675V10.8334H6.66667V9.16675H5.00001ZM1.66667 12.5001V14.1667H3.33334V12.5001H1.66667ZM16.6667 15.0001C15.7465 15.0001 15 15.7466 15 16.6667C15 17.5869 15.7465 18.3334 16.6667 18.3334C17.5868 18.3334 18.3333 17.5869 18.3333 16.6667C18.3333 15.7466 17.5868 15.0001 16.6667 15.0001ZM1.66667 15.8334V17.5001H3.33334V15.8334H1.66667ZM5.00001 15.8334V17.5001H6.66667V15.8334H5.00001ZM8.33334 15.8334V17.5001H10V15.8334H8.33334ZM11.6667 15.8334V17.5001H13.3333V15.8334H11.6667Z"
        fill="currentColor"
      />
    </svg>
  )
}

ProcessPathIcon.displayName = 'ProcessPathIcon'
