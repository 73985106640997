import { RenderElementProps } from 'slate-react'

import { MentionElement } from '@/features/task/components/chat/input/types'
import { isMac } from '@/lib/utils'

const IS_MAC = isMac()

export const Mention = ({
  attributes,
  children,
  element,
}: {
  attributes: RenderElementProps['attributes']
  children: RenderElementProps['children']
  element: MentionElement
}) => {
  return (
    <span {...attributes} contentEditable={false} className="inline text-gold">
      {IS_MAC ? (
        // Mac OS IME https://github.com/ianstormtaylor/slate/issues/3490
        <>
          {children}@{element.fullName}
        </>
      ) : (
        // Others like Android https://github.com/ianstormtaylor/slate/pull/5360
        <>
          @{element.fullName}
          {children}
        </>
      )}
    </span>
  )
}

Mention.displayName = 'Mention'
