import { useCallback } from 'react'

import { Button } from '@/components/ui/button/Button'
import { RefineTaskInput } from '@/gql/generated/graphql'

interface SubtasksRefinementErrorProperties {
  onCancel: () => void
  refineTask: (input?: Omit<RefineTaskInput, 'taskId'>) => void
}

export const SubtasksRefinementError = ({
  onCancel,
  refineTask,
}: SubtasksRefinementErrorProperties) => {
  const handlePlanning = useCallback(() => {
    refineTask({ precedentTaskIds: [] })
  }, [refineTask])

  return (
    <div className="relative h-[358px] rounded-2xl border border-film-subtle border-indigo-300 bg-gradient-indigo px-4 py-3.5">
      <p className="text-xs uppercase text-indigo-600">PLAN</p>
      <div className="absolute left-[50%] top-[50%] flex translate-x-[-50%] translate-y-[-50%] flex-col items-center space-y-7 text-center">
        <div className="space-y-3 text-indigo-700 lg:w-[376px]">
          <p className="text-md font-medium">No relevant context found</p>
          <p className="text-sm">
            There is no external context relevant to completing this task. You
            can still create an ungrounded plan, based on the information within
            this task.
          </p>
        </div>
        <div className="space-y-1.5 lg:w-[296px]">
          <Button
            variant="solidIndigo"
            size="sm"
            className="w-full"
            onClick={handlePlanning}
          >
            Create ungrounded plan
          </Button>
          <Button size="sm" className="w-full" onClick={onCancel}>
            Plan manually instead
          </Button>
        </div>
      </div>
    </div>
  )
}

SubtasksRefinementError.displayName = 'SubtasksRefinementError'
