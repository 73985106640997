import { IconComponent } from '@/lib/types'

export const ApproveIcon: IconComponent = ({ ...properties }) => {
  return (
    <svg
      width="21"
      height="20"
      viewBox="0 0 21 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...properties}
    >
      <path
        d="M9.5702 1.7098C9.37055 1.71414 9.18609 1.81396 9.07324 1.9789C9.07324 1.9789 8.41786 2.93159 7.62359 4.01449C6.82932 5.09522 5.86144 6.33219 5.40137 6.76839C4.53331 7.5887 3.91699 8.7085 3.91699 9.97586V12.7081C3.91699 15.347 6.06977 17.4997 8.70866 17.4997H12.5997C14.1709 17.4997 15.5055 16.3213 15.7008 14.7654L15.9179 13.2115L16.2673 10.7115L16.4105 9.69157C16.6036 8.32438 15.5229 7.08306 14.1405 7.08306H10.6119C10.9005 6.36909 11.1132 5.72239 11.2607 4.99322C11.3475 4.56787 11.4213 4.23367 11.4213 3.87777C11.4213 3.02056 10.983 2.40641 10.5598 2.09825C10.1344 1.79009 9.6939 1.71631 9.6939 1.71631C9.65267 1.7098 9.61144 1.70763 9.5702 1.7098ZM9.80892 3.10303C9.8176 3.10737 9.81543 3.10303 9.82628 3.10954C10.0042 3.23975 10.1713 3.396 10.1713 3.87777C10.1713 4.03402 10.1214 4.32265 10.0368 4.74582C9.8827 5.49886 9.70909 6.08697 9.38574 6.84001V6.84218C9.25119 7.15902 9.26421 7.51709 9.42915 7.81223C9.59625 8.10954 9.94998 8.33306 10.3232 8.33306H14.1405C14.785 8.33306 15.2625 8.88211 15.1735 9.51796L15.0303 10.5379L14.6809 13.0379L14.4617 14.5983C14.4617 14.6004 14.4617 14.6048 14.4617 14.6069C14.3423 15.5509 13.5502 16.2497 12.5997 16.2497H8.70866C6.74468 16.2497 5.16699 14.672 5.16699 12.7081V9.97586C5.16699 9.11431 5.58583 8.31353 6.26074 7.67551C6.91178 7.05919 7.82324 5.85259 8.63053 4.75233C9.27507 3.87343 9.61144 3.38515 9.80892 3.10303Z"
        fill="currentColor"
      />
    </svg>
  )
}

ApproveIcon.displayName = 'ApproveIcon'
