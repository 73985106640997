import { useMutation, useQueryClient } from '@tanstack/react-query'
import { toast } from 'react-toastify'

import { useApi } from '@/contexts/ApiProvider'
import { FetchTasksByIdQuery } from '@/gql/generated/graphql'
import { logger } from '@/lib/logger'

type Task = FetchTasksByIdQuery['tasks'][number]

export const useUpdateTaskStatus = (taskId: string) => {
  const api = useApi()
  const queryClient = useQueryClient()

  const taskQueryKey = ['task', taskId]

  const taskStatusMutation = useMutation({
    mutationFn: api.setTaskStatus,
    onMutate: async ({ id, status }) => {
      const previousState = queryClient.getQueryData<Task>(taskQueryKey)
      queryClient.setQueryData<Task>(taskQueryKey, (previousState) => {
        /* v8 ignore next */
        if (!previousState) return

        const newStatus = {
          completedAt:
            status === 'COMPLETED' ? new Date().toISOString() : undefined,
          status,
        }

        if (taskId === id) {
          return {
            ...previousState,
            ...newStatus,
          }
        }

        return {
          ...previousState,
          subtasks: previousState.subtasks.map((task) => {
            if (task.id === id) {
              return {
                ...task,
                ...newStatus,
              }
            }

            return task
          }),
        }
      })

      return { previousState }
    },
    // Partition: Important to keep these definitions below onMutate property so the TS compiler infers context properly
    onError: (error, _, context) => {
      queryClient.setQueryData(taskQueryKey, context?.previousState)
      logger.error(error)
      toast.error('Task update failed!')
    },
  })

  return {
    isUpdatingTaskStatus: taskStatusMutation.isPending,
    updateTaskStatus: taskStatusMutation.mutate,
  }
}
