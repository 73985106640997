import { Link } from '@tanstack/react-router'
import { useCallback } from 'react'

import { TrashIcon } from '@/components/icons/TrashIcon'
import { UnreadIndicatorIcon } from '@/components/icons/UnreadIndicatorIcon'
import { Avatar } from '@/components/ui/avatar/Avatar'
import { Button } from '@/components/ui/button/Button'
import { useDeleteNotifications } from '@/features/notification/hooks/useDeleteNotifications'
import { useMarkNotificationsAsRead } from '@/features/notification/hooks/useMarkNotificationsAsRead'
import { ViewerNotification } from '@/features/notification/hooks/useNotificationPull'
import { ChatMessage } from '@/features/task/components/chat/ChatMessage'
import { useUsers } from '@/features/task/hooks/useUsers'
import { cn, formatMessageTimestamp } from '@/lib/utils'

interface NotificationProperties {
  notification: ViewerNotification
  onClick?: () => void
  hideTimestamp?: boolean
  index?: number
  className?: string
  deletable?: boolean
}

export const Notification = ({
  className,
  deletable,
  hideTimestamp,
  index,
  notification,
  onClick,
}: NotificationProperties) => {
  const readableTaskId = `TASK-${notification.task?.id.slice(-4)}`
  const { users } = useUsers()

  const deleteNotificationsMutation = useDeleteNotifications()
  const markNotificationsAsReadMutation = useMarkNotificationsAsRead()

  const handleDelete = useCallback(
    (id: string) => (event: React.MouseEvent<HTMLButtonElement>) => {
      event.preventDefault() // Prevent navigation
      deleteNotificationsMutation.mutate([id])
    },
    [deleteNotificationsMutation]
  )

  const isLinkDisabled = !notification.task
  const linkTo = isLinkDisabled ? '#' : `/all-work/${notification.task?.id}`
  const handleLinkClick: React.MouseEventHandler<HTMLAnchorElement> =
    useCallback(
      (event) => {
        if (isLinkDisabled) {
          event.preventDefault() // Prevent navigation
        } else {
          if (notification.isUnread) {
            markNotificationsAsReadMutation.mutate([notification.id])
          }
          onClick?.()
        }
      },
      [
        isLinkDisabled,
        markNotificationsAsReadMutation,
        notification.id,
        notification.isUnread,
        onClick,
      ]
    )

  return (
    <Link
      data-testid={
        isLinkDisabled || !onClick
          ? `disabled-notification-task`
          : `open-notification-task-${notification.task?.id}`
      }
      to={linkTo}
      onClick={handleLinkClick}
      disabled={isLinkDisabled || !onClick}
    >
      <div
        className={cn(
          'rounded-2xl border-film-faint p-3',
          {
            'bg-mono-paper': !isLinkDisabled && onClick,
            'bg-mono-paper-translucent': isLinkDisabled,
            'hover:border-film-subtle hover:bg-film-subtle focus:bg-sky-100':
              onClick,
          },
          className
        )}
        data-testid={`viewer-notification-${index}`}
      >
        <>
          <div className="flex gap-2">
            <div>
              <Avatar
                src={notification.sourceMessage?.author.avatarUrl}
                alt={notification.sourceMessage?.author.name}
                className="size-8 min-w-8 rounded-full"
              />
            </div>
            <div className="flex flex-col gap-2 truncate">
              <div className="flex items-center gap-2">
                {!isLinkDisabled && notification.isUnread && onClick && (
                  <UnreadIndicatorIcon
                    className="shrink-0 text-blue-ink-subtle"
                    data-testid="notification-unread-indicator"
                  />
                )}

                <p className="text-sm-regular text-mono-ink-strong">
                  {notification.sourceMessage?.author.name}
                  <span className="text-mono-ink-subtle">
                    {' mentioned you ' +
                      `${notification.task?.id ? ' in' : ''} `}
                  </span>
                  {notification.task?.id && (
                    <span className="-ml-0.5 p-1">{readableTaskId}</span>
                  )}
                </p>
                {!hideTimestamp && (
                  <span className="truncate overflow-ellipsis text-xs-regular text-film-strongest">
                    {formatMessageTimestamp(
                      notification.sourceMessage?.createdAt ??
                        notification.createdAt
                    )}
                  </span>
                )}
              </div>
              <p className="w-fit min-w-1 whitespace-pre-wrap rounded-lg border bg-film-subtle p-3">
                {notification.sourceMessage ? (
                  <ChatMessage
                    message={notification.sourceMessage?.body.text ?? ''}
                    users={users}
                  />
                ) : (
                  <span
                    className="text-film-strongest"
                    data-testid={`message-deleted`}
                  >
                    This message has been deleted
                  </span>
                )}
              </p>
            </div>
            {deletable && (
              <div className="ml-auto">
                <Button
                  className="relative size-11 w-11 rounded-full"
                  size="sm"
                  data-testid={`delete-button-${notification.id}`}
                  onClick={handleDelete(notification.id)}
                >
                  <TrashIcon />
                </Button>
              </div>
            )}
          </div>
        </>
      </div>
    </Link>
  )
}

Notification.displayName = 'Notification'
