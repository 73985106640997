import { IconComponent } from '@/lib/types'

export const RadioCircleSolidTinted: IconComponent<{
  innerCircleClass?: string
  outerCircleClass?: string
}> = ({ innerCircleClass, outerCircleClass, ...properties }) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...properties}
    >
      <circle
        cx="12"
        cy="12"
        r="8.5"
        className={outerCircleClass}
        stroke="#0284C7"
      />
      <circle
        cx="12"
        cy="12"
        r="4.25"
        className={innerCircleClass}
        stroke="#075985"
        strokeWidth="0.5"
      />
    </svg>
  )
}

RadioCircleSolidTinted.displayName = 'RadioCircleSolidTinted'
