import { ClassValue, clsx } from 'clsx'
import { differenceInDays } from 'date-fns/differenceInDays'
import { formatDistance } from 'date-fns/formatDistance'
import isError from 'lodash/isError'
import isString from 'lodash/isString'
import { twMerge } from 'tailwind-merge'

/**
 * A Utility function to efficiently merge Tailwind CSS classes in JS without style conflicts.
 *
 * Usage:
 * ```tsx
 * <div className={cn('w-32 h-32', className)} />
 * ```
 *
 * @param {string} inputs the variants and classNames
 * @returns {string} the merged classnames
 */

export const cn = (...inputs: ClassValue[]): string => twMerge(clsx(inputs))

/**
 *
 * @param {string} avatarUrl the url of the avatar
 * @returns {string} modified hosted avatar url
 */

export const makeErrorFromUnknown = (input: unknown): Error =>
  isError(input) ? input : new Error(isString(input) ? input : 'Unknown error.')

/**
 * A function that merges React refs into one.
 * Supports both functions and ref objects created using createRef() and useRef().
 *
 * Usage:
 * ```tsx
 * <div ref={mergeRefs(ref1, ref2, ref3)} />
 * ```
 *
 * @param {(React.Ref<T> | undefined)[]} inputReferences Array of refs
 * @returns {React.Ref<T> | React.RefCallback<T>} Merged refs
 */

// React refs are called "refs" and not "references".
// This function merges react refs
// eslint-disable-next-line unicorn/prevent-abbreviations
export const mergeRefs = <T>(
  ...inputReferences: Array<React.Ref<T> | undefined>
): React.Ref<T> | React.RefCallback<T> | undefined => {
  const filteredInputReferences = inputReferences.filter(Boolean)

  if (filteredInputReferences.length <= 1) {
    const firstReference = filteredInputReferences[0]

    return firstReference ?? undefined
  }

  return function mergedReferences(reference) {
    for (const inputReference of filteredInputReferences) {
      if (typeof inputReference === 'function') {
        inputReference(reference)
      } else if (inputReference) {
        ;(inputReference as React.MutableRefObject<T | null>).current =
          reference
      }
    }
  }
}

export const isMac = () => navigator.userAgent.toUpperCase().includes('MAC')

export const callWith =
  <T extends []>(...arguments_: T) =>
  (function_: (...arguments__: T) => void) => {
    function_(...arguments_)
  }

export const callAll =
  <T extends []>(...fns: Array<(...arguments_: T) => void>) =>
  (...arguments_: T) =>
    fns.map(callWith(...arguments_))

export const isNotNil = <T>(value?: T): value is NonNullable<T> =>
  value !== null && value !== undefined

export const formatMessageTimestamp = (timestamp: string) => {
  let formattedTimeStamp: string

  try {
    const date = new Date(timestamp)
    const now = new Date()
    const diff = differenceInDays(now, date)
    if (diff > 2) {
      return date.toLocaleDateString()
    }

    formattedTimeStamp = `${formatDistance(now, date)} ago`
  } catch {
    return timestamp
  }

  return formattedTimeStamp
}

export const isCtrlPressed = (event: { metaKey: boolean; ctrlKey: boolean }) =>
  isMac() ? event.metaKey : event.ctrlKey
