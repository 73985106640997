import { IconComponent } from '@/lib/types'

export const ArrowRightIcon: IconComponent = ({ ...properties }) => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...properties}
    >
      <path
        d="M11.035 3.74341C10.7811 3.74341 10.5511 3.89749 10.4556 4.13403C10.3601 4.36841 10.4166 4.63968 10.5989 4.81763L15.3666 9.58325H3.12489C2.8992 9.58108 2.69086 9.69827 2.57585 9.89358C2.463 10.0889 2.463 10.3276 2.57585 10.5229C2.69086 10.7182 2.8992 10.8354 3.12489 10.8333H15.3666L10.5989 15.5989C10.4361 15.7573 10.371 15.9895 10.4274 16.2087C10.4838 16.4279 10.6553 16.5993 10.8745 16.6557C11.0936 16.7122 11.3258 16.6471 11.4843 16.4843L17.3176 10.651C17.5607 10.4057 17.5607 10.0108 17.3176 9.76554L11.4843 3.93221C11.3649 3.81285 11.2043 3.74341 11.035 3.74341Z"
        fill="currentColor"
      />
    </svg>
  )
}

ArrowRightIcon.displayName = 'ArrowRightIcon'
