import { createFileRoute } from '@tanstack/react-router'
import { z } from 'zod'

import { NotFound } from '@/features/task/components/NotFound'
import { TaskBreadcrumb } from '@/features/task/components/TaskBreadcrumb'

export const TASK_DETAILS_TABS = ['thread', 'flowchart', 'relevant'] as const

const parseParameters = (parameters: Record<'taskId', string>) => ({
  taskId: z.string().parse(parameters.taskId),
})

export const Route = createFileRoute('/_private/all-work/$taskId')({
  notFoundComponent: NotFound,
  parseParams: parseParameters,
  validateSearch: z.object({
    tab: z.optional(z.enum(TASK_DETAILS_TABS)),
  }),
})

Route.interloom = {
  Breadcrumb: TaskBreadcrumb,
}
