import {
  useRef,
  Suspense,
  useCallback,
  useEffect,
  useState,
  useMemo,
  ReactNode,
} from 'react'

/** The problem with standard Suspense is that it doesn't wait for the fallback to be displayed for a minimum duration, neither does it allow delaying the fallback display.
 * It results in a flicker when the fallback is displayed and the connection/database is fast.
 * https://github.com/facebook/react/issues/17351#issuecomment-1238698169
 * https://codesandbox.io/p/sandbox/react-17-smartsuspense-simplified-github-https-github-com-facebook-react-issues-17351-l26wor?file=%2Fsrc%2Findex.js%3A12%2C1
 *
 * I'd consider the whole thing a hack. It could be replaced with a `use` react hook as soon as it is stable. When it is avaliable we could pass a `Promise` that resolves after the minimum duration into it.
 */
const PromiseThrower = () => {
  // eslint-disable-next-line @typescript-eslint/only-throw-error
  throw new Promise(() => {})
}

interface FallbackDelayerProperties {
  fallback: ReactNode
  fallbackDelayMs?: number
  onShowFallback: () => void
}

const FallbackDelayer = ({
  fallback,
  fallbackDelayMs,
  onShowFallback,
}: FallbackDelayerProperties) => {
  const [showFallback, setShowFallback] = useState(false)

  useEffect(() => {
    if (fallbackDelayMs) {
      const timeoutId = setTimeout(() => {
        setShowFallback(true)
        onShowFallback()
      }, fallbackDelayMs)

      return () => {
        clearInterval(timeoutId)
      }
    } else {
      setShowFallback(true)
      onShowFallback()
    }
  }, [fallbackDelayMs, onShowFallback])

  return showFallback ? fallback : undefined
}

FallbackDelayer.displayName = 'FallbackDelayer'

interface SmartSuspenseProperties {
  children: ReactNode
  fallback: ReactNode
  fallbackDelayMs?: number
  fallbackMinDurationMs?: number
}

export const SmartSuspense = ({
  children,
  fallback: fallbackProperty,
  fallbackDelayMs = 0,
  fallbackMinDurationMs = 0,
}: SmartSuspenseProperties) => {
  const [isWaitingFallbackMinDurationMs, setIsWaitingFallbackMinDurationMs] =
    useState(false)

  const timeoutIdReference = useRef<NodeJS.Timeout>()

  const startWaitingFallbackMinDurationMs = useCallback(() => {
    setIsWaitingFallbackMinDurationMs(true)

    timeoutIdReference.current && clearInterval(timeoutIdReference.current)
    timeoutIdReference.current = setTimeout(() => {
      setIsWaitingFallbackMinDurationMs(false)
    }, fallbackMinDurationMs)
  }, [fallbackMinDurationMs])

  useEffect(() => {
    return () => {
      timeoutIdReference.current && clearInterval(timeoutIdReference.current)
    }
  }, [])

  const fallback = useMemo(() => {
    return (
      <FallbackDelayer
        fallback={fallbackProperty}
        fallbackDelayMs={fallbackDelayMs}
        onShowFallback={startWaitingFallbackMinDurationMs}
      />
    )
  }, [fallbackProperty, fallbackDelayMs, startWaitingFallbackMinDurationMs])

  return (
    <Suspense fallback={fallback}>
      {isWaitingFallbackMinDurationMs && <PromiseThrower />}
      {children}
    </Suspense>
  )
}

SmartSuspense.displayName = 'SmartSuspense'
