import { useCallback, useMemo } from 'react'

import { AlertDialog } from '@/components/ui/alertDialog/AlertDialog'
import { SubtaskDialogItem } from '@/features/task/components/subtasks/SubtaskDialogItem'
import { FetchTasksByIdQuery } from '@/gql/generated/graphql'

interface PasteTaskDialogProperties {
  onMoveTasksClick: (id: string) => void
  onOpenChange: (open: boolean) => void
  isDialogOpen: boolean
  taskToMove: FetchTasksByIdQuery['tasks'][number]['subtasks'][number]
}

export const PasteTasksDialog = ({
  isDialogOpen,
  onMoveTasksClick,
  onOpenChange,
  taskToMove,
}: PasteTaskDialogProperties) => {
  const onActionClick = useCallback(() => {
    onMoveTasksClick(taskToMove.id)
  }, [onMoveTasksClick, taskToMove])

  const DialogItem = useMemo(
    () => (
      <SubtaskDialogItem
        taskId={taskToMove.id}
        title={taskToMove.title}
        isCompleted={!!taskToMove.completedAt}
        assignee={taskToMove.assignee}
      />
    ),
    [taskToMove]
  )

  return (
    <AlertDialog
      actionText="Move"
      cancelText="Cancel"
      description="The thread and subordinate tasks will also be moved."
      onActionClick={onActionClick}
      onOpenChange={onOpenChange}
      title="Move this task?"
      isDialogOpen={isDialogOpen}
      beforeTitle={DialogItem}
    />
  )
}

PasteTasksDialog.displayName = 'PasteTasksDialog'
