import { RenderElementProps } from 'slate-react'

import { Mention } from '@/features/task/components/chat/input/Mention'

export const Element = (properties: RenderElementProps) => {
  const { attributes, children, element } = properties
  if (!('type' in element)) {
    return <p {...attributes}>{children}</p>
  }

  switch (element.type) {
    case 'mention': {
      return <Mention {...properties} element={element} />
    }
    default: {
      return <p {...attributes}>{children}</p>
    }
  }
}

Element.displayName = 'Element'
