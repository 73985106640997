import { CheckedCircleSolidIcon } from '@/components/icons/CheckedCircleSolidIcon'
import { CircleCrossFilledIcon } from '@/components/icons/CircleCrossFilledIcon'
import { TaskCircularCheckboxEmptyIcon } from '@/components/icons/TaskCircularCheckboxEmptyIcon'
import Select from '@/components/ui/select/select'
import { TaskIcon } from '@/features/task/components/TaskIcon'
import { TaskStatus } from '@/gql/generated/graphql'

interface TaskStatusSelectProperties {
  onSelect: (value: TaskStatus) => void
  isPending: boolean
  taskStatus: TaskStatus
}

export const TaskStatusSelect = ({
  isPending,
  onSelect,
  taskStatus,
}: TaskStatusSelectProperties) => {
  return (
    <Select.Root
      disabled={isPending}
      onValueChange={onSelect}
      value={taskStatus}
    >
      <Select.Trigger
        className="flex-1"
        data-testid="dropdown-status-picker"
        icon={
          <TaskIcon className="size-6 min-h-6 min-w-6" status={taskStatus} />
        }
      />
      <Select.Content data-testid="select-viewport">
        <p className="mb-1 border-b border-film-subtle px-2 pb-1 text-xs-bold text-mono-ink-subtle">
          Status
        </p>

        <div className="flex flex-col gap-1">
          <Select.Item
            Icon={TaskCircularCheckboxEmptyIcon}
            text="Open"
            value="OPEN"
          />
          <Select.Item
            Icon={CheckedCircleSolidIcon}
            text="Completed"
            value="COMPLETED"
          />
          <Select.Item
            Icon={CircleCrossFilledIcon}
            text="Cancelled"
            value="CANCELLED"
          />
        </div>
      </Select.Content>
    </Select.Root>
  )
}

TaskStatusSelect.displayName = 'TaskStatusSelect'
