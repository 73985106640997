import { IconComponent } from '@/lib/types'

export const CalendarIcon: IconComponent = ({ ...properties }) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...properties}
  >
    <path
      d="M6.25 3C4.46354 3 3 4.46354 3 6.25V17.75C3 19.5365 4.46354 21 6.25 21H17.75C19.5365 21 21 19.5365 21 17.75V6.25C21 4.46354 19.5365 3 17.75 3H6.25ZM6.25 4.5H17.75C18.724 4.5 19.5 5.27604 19.5 6.25V7H4.5V6.25C4.5 5.27604 5.27604 4.5 6.25 4.5ZM4.5 8.5H19.5V17.75C19.5 18.724 18.724 19.5 17.75 19.5H6.25C5.27604 19.5 4.5 18.724 4.5 17.75V8.5ZM7.75 10.5C7.0599 10.5 6.5 11.0599 6.5 11.75C6.5 12.4401 7.0599 13 7.75 13C8.4401 13 9 12.4401 9 11.75C9 11.0599 8.4401 10.5 7.75 10.5ZM12 10.5C11.3099 10.5 10.75 11.0599 10.75 11.75C10.75 12.4401 11.3099 13 12 13C12.6901 13 13.25 12.4401 13.25 11.75C13.25 11.0599 12.6901 10.5 12 10.5ZM16.25 10.5C15.5599 10.5 15 11.0599 15 11.75C15 12.4401 15.5599 13 16.25 13C16.9401 13 17.5 12.4401 17.5 11.75C17.5 11.0599 16.9401 10.5 16.25 10.5ZM7.75 15C7.0599 15 6.5 15.5599 6.5 16.25C6.5 16.9401 7.0599 17.5 7.75 17.5C8.4401 17.5 9 16.9401 9 16.25C9 15.5599 8.4401 15 7.75 15ZM12 15C11.3099 15 10.75 15.5599 10.75 16.25C10.75 16.9401 11.3099 17.5 12 17.5C12.6901 17.5 13.25 16.9401 13.25 16.25C13.25 15.5599 12.6901 15 12 15Z"
      fill="black"
    />
  </svg>
)

CalendarIcon.displayName = 'CalendarIcon'
