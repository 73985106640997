import { useParams } from '@tanstack/react-router'
import { isAfter } from 'date-fns'
import { Suspense, useEffect, useMemo, useRef } from 'react'
import { Slide, toast } from 'react-toastify'

import { NotificationToast } from '@/features/notification/components/NotificationToast'
import { useMarkNotificationsAsRead } from '@/features/notification/hooks/useMarkNotificationsAsRead'
import { useViewerWithNotifications } from '@/features/notification/hooks/useViewerWithNotifications'
import { FetchViewerWithNotificationsQuery } from '@/gql/generated/graphql'

export type ViewerNotification =
  FetchViewerWithNotificationsQuery['viewer']['notifications'][0]

export const useNotificationPull = (isNotificationSidebarOpened: boolean) => {
  const { mutate: markNotificationsAsReadMutation } =
    useMarkNotificationsAsRead()

  const newestNotification = useRef<ViewerNotification>()
  const isLastRequestWasEmpty = useRef(false)

  const { taskId } = useParams({ strict: false })

  const {
    viewer: { notifications },
  } = useViewerWithNotifications(true)

  const hasUnreadNotifications = useMemo(
    () => notifications.some((notification) => notification.isUnread),
    [notifications]
  )

  useEffect(() => {
    let newestIncomingNotification: ViewerNotification | undefined
    for (const notification of notifications) {
      if (
        !newestIncomingNotification ||
        isAfter(notification.createdAt, newestIncomingNotification.createdAt)
      ) {
        newestIncomingNotification = notification
        if (
          newestIncomingNotification.isUnread &&
          newestIncomingNotification.task?.id === taskId
        ) {
          markNotificationsAsReadMutation([newestIncomingNotification.id])
        }
      }
    }

    const previousLastNotification = newestNotification.current
    if (isNotificationSidebarOpened) {
      isLastRequestWasEmpty.current = notifications.length === 0
      newestNotification.current = newestIncomingNotification
      return
    }

    const newNotifications = notifications.filter(
      (notification) =>
        (isLastRequestWasEmpty.current ||
          (previousLastNotification &&
            isAfter(
              notification.createdAt,
              previousLastNotification.createdAt
            ))) &&
        taskId !== notification.task?.id
    )

    isLastRequestWasEmpty.current = notifications.length === 0
    newestNotification.current = newestIncomingNotification

    for (const notification of newNotifications) {
      toast(
        (properties) => (
          <Suspense>
            <NotificationToast {...properties} />
          </Suspense>
        ),
        {
          autoClose: 5000,
          className: 'w-[28rem] !rounded-2xl',
          closeButton: false,
          data: {
            markNotificationsAsReadMutation,
            notification,
          },
          hideProgressBar: true,
          pauseOnHover: true,
          transition: Slide,
        }
      )
    }
  }, [
    isNotificationSidebarOpened,
    markNotificationsAsReadMutation,
    notifications,
    taskId,
  ])

  return { hasUnreadNotifications }
}
